import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'filter'})
export class CustomFilterPipe implements PipeTransform {
    transform(values: any[], callback?: any, reverse?: boolean, enable?: boolean): any[] {
        if (!enable) {
            return values;
        }
        if (typeof callback === 'function') {
            let temp = values.filter(a => reverse ? !callback(a) : callback(a));
            console.log(temp.length);
            return temp;
        }

        return values;
    }
}
