import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Storage} from '@ionic/storage';

/*
  Generated class for the TermsConditions page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/

declare var window;

@Component({
    selector: 'page-secure-verify',
    templateUrl: 'secure-verify-page.html',
    styleUrls: ['secure-verify-page.scss']
})
export class SecureVerifyPage extends MobileBasePage implements OnInit {
    @ViewChild('secure') secureFrame;
    body = '';
    url: string;

    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    constructor(injector: Injector, public storage: Storage) {
        super(injector);
        this.body = this.getNavParams('body');
        this.url = this.getNavParams('url');

    }

    ngOnInit() {
        window.closeFrame = (result) => {
            const status = result ? 'success' : 'failed';
            this.modalController.dismiss(status);
            // this.dismiss();
        };
        if (this.body) {
            this.secureFrame.nativeElement.contentWindow.document.write(this.body);
            this.secureFrame.nativeElement.contentWindow.document.close();
        } else if (this.url) {
            this.secureFrame.nativeElement.src = this.url;
        }


        // this.secureFrame.nativeElement.contentWindow.onclose = () => {
        //         console.log("onclose!");
        //         // this.dismiss();
        //
        // };this.secureFrame.nativeElement.contentWindow.document.onchange = () => {
        //         console.log("onchange!");
        //         // this.dismiss();
        //
        // };this.secureFrame.nativeElement.contentWindow.onunload = ($event) => {
        //         console.log("onunload!",$event);
        //         // this.dismiss();
        //
        // };
        // this.secureFrame.nativeElement.contentWindow.document.body.onchange = ($event) => {
        //         console.log("verifed!");
        //         // this.dismiss();
        // };


    }

    dismiss(agree?: boolean) {
        console.log('dismiss');
        if (agree) {
            this.modalController.dismiss();

        } else {
            this.modalController.dismiss();

        }
        // this.push(BrandStoreListPage, {brand:5637145314197504});
    }


}
