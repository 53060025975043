import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import * as firebase from 'firebase';
import 'firebase/database';
import 'rxjs/add/operator/map';
import {Order, OrderItem, OrderManager} from 'aigens-ng-core';
import {MemberService} from './member.service';
import {LoadingController, ToastController} from '@ionic/angular';


/**
 *  Realtime Sharing Cart Service
 * */
@Injectable({
    providedIn: 'root'
})
export class CartService extends BaseService {

    database: any;
    refs: any[] = [];
    refsByKey: any = {};
    enable = false;
    sharingCart: {};
    myCart: OrderItem[];
    storeId: number;
    spot: number;
    key: any;
    lastLock: any = null;
    locking = false;
    initAt: number;
    loader = null;

    constructor(public loadingController: LoadingController, public toastController: ToastController,
                public memberService: MemberService, public configs: ConfigService, public orderManger: OrderManager) {
        super();

        if (this.configs.isChina()) {
            return;
        }
        let config = this.configs.config.getFirebaseConfig();
        if (!this.configs.firebase) {
            this.configs.firebase = firebase.initializeApp(config);
            firebase.auth();
        }
        this.database = firebase.database();

    }

    initSharingCart(storeId: number, spot: number) {
        if (Number.isNaN(spot) || !spot || !this.enable) {
            console.log('invalid spot, not init sharing cart');
            this.enable = false;
            return;
        }
        console.log('init sharing cart');
        this.enable = true;
        firebase.database().ref(`/cart/${storeId}/${spot}/members`).once('value', (data) => {
            this.sharingCart = data.val();
            console.log('sharing cart init', this.sharingCart);
            this.lastLock = null;
            this.locking = false;
            this.storeId = storeId;
            this.spot = spot;
            this.initAt = Date.now();
            if (this.configs.getLocal('realcart.key') && this.sharingCart && this.sharingCart[this.configs.getLocal('realcart.key')]) {
                this.key = this.configs.getLocal('realcart.key');
                if (this.sharingCart[this.key]['orderitems'] && this.orderManger.order) {
                    this.orderManger.order.orderitems = this.sharingCart[this.key]['orderitems'];
                    this.orderManger.calculate();
                }
                firebase.database().ref(`/cart/${storeId}/${spot}/members/${this.key}/join`).push(Date.now());
            } else {
                // TODO: implement initialization data
                let toSave = {
                    avatar: this.getAvatarUrl(this.memberService.name),
                    uuid: this.memberService.uuid,
                    name: this.memberService.name,
                    orderitems: [],
                    join: Date.now()
                };
                this.key = firebase.database().ref(`/cart/${storeId}/${spot}/members`).push(toSave).key;
                this.configs.setLocal('realcart.key', this.key);
            }

            // On your order item changes, this may trigger by you or someone submit your items
            firebase.database().ref(`/cart/${this.storeId}/${this.spot}/members/${this.key}/orderitems`).on('value', (data) => {
                this.myCart = data.val();
                if (this.myCart === undefined) {
                    this.myCart = [];
                    if (this.orderManger.order) {
                        this.orderManger.order.orderitems = this.myCart;
                        this.orderManger.calculate();
                    }
                }
                console.log('your cart change', data.val());

            });
            firebase.database().ref(`/cart/${storeId}/${spot}/members`).on('value', (data) => {
                this.sharingCart = data.val();
                console.log('sharing cart change', this.sharingCart);
            });
            firebase.database().ref(`/cart/${storeId}/${spot}/members`).on('child_added', (data) => {
                console.log('on child_added', data.val());
                let t = data.val();
                if (t['uuid'] !== this.memberService.uuid && t['join'] > this.initAt) {
                    this.toastController.create({
                        message: 'someone joined',
                        duration: 2000,
                        position: 'top'
                    }).then(modal => {
                        modal.present();
                    });
                }
            });
            firebase.database().ref(`/cart/${storeId}/${spot}/members`).on('child_changed', (data) => {
                console.log('on child_changed', data.val());
                let t = data.val();
                if (t['uuid'] !== this.memberService.uuid) {
                    this.toastController.create({
                        message: 'someone changed the cart',
                        duration: 2000,
                        position: 'top'
                    }).then(modal => {
                        modal.present();
                    });
                }
            });
            firebase.database().ref(`/cart/${storeId}/${spot}/lock`).on('value', (data) => {
                console.log('on lock', data.val());
                let t = data.val();
                if (this.lastLock && !t) {
                    if (this.lastLock['uuid'] !== this.memberService.uuid) {
                        this.toastController.create({
                            message: 'current order is submitted',
                            duration: 2000,
                            position: 'top'
                        }).then(modal => {
                            modal.present();
                        });
                    }
                    if (this.loader) {
                        this.loader.dismiss();
                    }
                    this.locking = false;
                } else {
                    this.onCartLocked(data.val());
                }
            });

            this.orderManger.onOrderitemsChange.subscribe(data => {
                if (!this.locking) {
                    this.updateSharingCart();
                }
            });
        });

    }

    updateSharingCart() {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }

        let temp = this.orderManger.order.orderitems;
        console.log('temp input', temp);
        temp.forEach(orderitem => {
            Object.keys(orderitem).forEach(key => orderitem[key] === undefined && delete orderitem[key]);
            Object.keys(orderitem.category).forEach(key => orderitem.category[key] === undefined && delete orderitem.category[key]);
            if (orderitem.groups) {
                orderitem.groups.forEach(group => {
                    Object.keys(group).forEach(key => group[key] === undefined && delete group[key]);
                    if (group.items) {
                        group.items.forEach(item => {
                            Object.keys(item).forEach(key => item[key] === undefined && delete item[key]);
                            if (item.mgroups) {
                                item.mgroups.forEach(mgroup => {
                                    Object.keys(mgroup).forEach(key => mgroup[key] === undefined && delete mgroup[key]);
                                    if (mgroup.items) {
                                        mgroup.items.forEach(mitem => {
                                            Object.keys(mitem).forEach(key => mitem[key] === undefined && delete mitem[key]);
                                        });
                                    }
                                });
                            }
                        });
                    }

                });
            }

        });
        console.log('temp output', temp);
        this.myCart = temp;
        firebase.database().ref(`/cart/${this.storeId}/${this.spot}/members/${this.key}/orderitems`).set(temp);
    }

    getOthersCart(): Order[] {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return [];
        }

        let temp = [];
        Object.keys(this.sharingCart).forEach(memberKey => {
            if (memberKey !== this.key) {
                let t = this.sharingCart[memberKey];
                if (t['orderitems'] != null && t['orderitems'].lenthe !== 0) {
                    temp.push(this.sharingCart[memberKey]);
                }
            }
        });
        // console.log('others cart', temp);
        return temp;
    }


    lockSharingCart(): Promise<any> {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return Promise.resolve({committed: true});
        }

        let t = {
            uuid: this.memberService.uuid,
            name: this.memberService.name,
            lockTime: Date.now()
        };
        return firebase.database().ref(`/cart/${this.storeId}/${this.spot}/lock`).transaction(data => {
            console.log('on lock callbacks', data);
            if (data) {
                console.log('someone is saving the demo');

                return;
            } else {
                console.log('the lock is empty, can update');
                t.lockTime = Date.now();

                return t;
            }
        }, (error, isSuccess, data) => {
            console.log('error', error);
            console.log('isSuccess', isSuccess);
            console.log('data', data);
            if (isSuccess) {
                console.log('this.sharing cart', this.sharingCart);
                Object.keys(this.sharingCart).forEach(memberKey => {
                    let t = this.sharingCart[memberKey];
                    if (t.uuid === this.memberService.uuid) {
                        return;
                    }
                    let tt = t['orderitems'];
                    if (!tt) {
                        return;
                    }
                    console.log('lock loop', t);

                    t['orderitems'].forEach(orderitem => {
                        console.log('lock add', orderitem);
                        this.orderManger.addOrderItem(orderitem);
                    });
                });
            } else {
                this.onCartLocked(data.val());
            }


        });

    }

    unlockSharingCart() {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }
        firebase.database().ref(`/cart/${this.storeId}/${this.spot}/lock`).remove();
        Object.keys(this.sharingCart).forEach(memberKey => {
            firebase.database().ref(`/cart/${this.storeId}/${this.spot}/members/${memberKey}/orderitems`).remove();
        });
        this.sharingCart = {};
        this.locking = false;
    }

    getAvatarUrl(index) {
        return 'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + index;
    }

    onCartLocked(lock) {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }

        if (!this.locking && lock) {
            this.locking = true;
            if (lock['uuid'] !== this.memberService.uuid) {
                this.loadingController.create({
                    spinner: null,
                    keyboardClose: false,
                    message: 'someone is submitting current order',
                    translucent: true,
                    cssClass: 'custom-class custom-loading'
                }).then(loader => {
                    this.loader = loader;
                    loader.present();
                });
            }
            // this.toastController.create({
            //     message: lock['name'] + ' is submitting current order',
            //     duration: 2000,
            //     position: 'top'
            // }).then(modal => {
            //     modal.present();
            // });
            this.lastLock = lock;
        }
    }
}
