import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {createTranslateLoader} from '../../utilities/createTranslateLoader';
import {ConfigService} from '../../../core/services/config.service';
import {ThemeService} from '../../../core/services/theme.service';
import {CustomBackButtonComponent} from './custom-back-button.component';
import {ThrottleClickDirective} from '../../directives/throttle-click/throttle-click';
import {ThrottleClickModule} from '../../directives/throttle-click/throttle-click.module';

@NgModule({
    declarations: [CustomBackButtonComponent],
    exports: [
        CustomBackButtonComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ThrottleClickModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
    ]


})
export class CustomBackButtonModule {
}
