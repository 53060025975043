import { Injectable } from '@angular/core';
import { Address, Store, OrderItem, OrderManager, Order } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base-service';
import { OrderSessionService } from './order-session.service';
import { MemberService } from './member.service';

@Injectable({
  providedIn: 'root'
})
export class AddressManagerService extends BaseService {

  constructor(
    private http: HttpClient,
    public configs: ConfigService,
    public orderManager: OrderManager,
    public orderSessionService: OrderSessionService,
    public memberService: MemberService
  ) {
    super();
    this.aq = new AQuery(http, configs);
    this.addressArr = [];
    // this.addressArrOfStoreList = [];
  }
  
  aq: AQuery;
  addressArr: Address[];
  currentAddress: Address; // save the order using takeout-address
  couriers: any[] = [];
  currentCourier: any;
  isDelivery = true; // the switch of the delivery-feature
  isPickup: Boolean = true; // the mark of pick up

  pickupArr: any = [];
  pickupAddress: any; // save the store which will pick up to;

  note = ''; // delivery order remarks
  cutlery = false;
  contactless = false;
  isChanged = true;

  isDefault = false;
  isAddAddress = false; // control the add address seccuessly and then popup AddressDialog modal

  priceRulesOfCurrentAddress: any;
  
  sameAddressCouriersData: any;

  // addressArrOfStoreList: Address[];// store list page using address because cant call session.json to get address without storeId

  getAddresses(store: Store, storesList?) {
    let addresses = this.orderSessionService.getLocalAddresses(storesList);
    this.addressArr = this.calAddressesDistance(addresses, store);
    // this.addressArr.sort((a, b) => a['distance'] - b['distance']);
    return this.addressArr;
  }

  callCalculate(order, address, courierId ?: string): Observable<any> {
    const url = '/api/v1/menu/calculate.json';
    const aq = this.aq;
    aq.url = url;
    aq.method = 'post';

    aq.auth(true);
    let body = Order.toOrderData(order);
    // let params = {
    //   addressId: address['id'],
    //   phone: this.memberService.member && this.memberService.member['phone'],
    //   storeId: store['id'],
    //   type: 'delivery'
    // };
    // if (oi) params['orderitems'] = oi;
    // if (courierId) params['courierId'] = courierId;
    body['addressId'] = address['id'];
    if (courierId) body['courierId'] = courierId;
    aq.body = body;

    let search = {};
    if (this.orderManager && this.orderManager.store && this.orderManager.store.country) {
      search['country'] = this.orderManager.store.country;
    }
    aq.params = search;

    return aq.getJson().pipe(map((jo) => {
      return jo['data'];
    }));
  }

  getAddressesFromAddressJson(): Observable<any> {
    const url = '/api/v1/store/address.json';
    const aq = this.aq;
    aq.url = url;
    aq.method = 'get';
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {
      let addressArr = jo['data'];
      // this.setAddressArrOfStoreList(addressArr);
      this.addressArr = [...addressArr];
      return addressArr;
    }));
  }

  // setAddressArrOfStoreList(addressArr: Address[]) {
  //   this.addressArrOfStoreList = addressArr;
  // }

  // getAddressArrOfStoreList() {
  //   return this.addressArrOfStoreList && this.addressArrOfStoreList.length > 0 ? this.addressArrOfStoreList : [];
  // }

  getPositionByAddressId(addressId) {
    let address = this.addressArr.find(address => address['id'] === addressId);
    return this.getPositionByAddress(address);
  }

  getPositionByAddress(address: Address) {
    if (address) {
      let latlng = { 'latitude': address.latitude, 'longitude': address.longitude };
      return latlng;
    } else {
      return null;
    }
  }

  postAddress(address: any, country?): Observable<any> {
    const url = '/api/v1/store/address.json';
    // let url = this.getOrderSesisonUrl(storeId,mode,'addresses');
    // todo change new api return val to old api val form

    const aq = this.aq;

    if (country && !address['country']) {
      address['country'] = country;
    }else if (this.orderManager && this.orderManager.store && this.orderManager.store.country && !address['country']) {
      address['country'] = this.orderManager.store.country;
    }
    const params = address;
    aq.url = url;
    aq.method = 'post';
    aq.params = params;
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {

      return jo['data'];
    }));
  }

  updateFormByPostal(postal, country = 'SG', autofill: boolean = true, geocode: boolean = true) {
    const url = '/api/v1/store/address.json';
    const params = {
      autofill: false,
      geocode: false,
      type: 'temp'
    };
    if (country) {
      params['country'] = country;
    }
    if (autofill) {
      params['autofill'] = autofill;
    }
    if (postal) {
      params['postal'] = postal;
    }
    if (geocode) {
      params['geocode'] = geocode;
    }
    const aq = this.aq;
    aq.url = url;
    aq.method = 'post';
    aq.params = params;
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {

      return jo['data'];
    }));
  }


  deleteAddress(addressId): Observable<any> {
    if (!addressId) return;
    const url = `/api/v1/store/address/${addressId}.json`;

    const aq = this.aq;

    let search = {};
    if (this.orderManager && this.orderManager.store && this.orderManager.store.country) {
      search['country'] = this.orderManager.store.country;
    }
    aq.params = search;

    aq.url = url;
    aq.method = 'delete';
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {
      // local data update
      let index = this.addressArr.findIndex((value) => {
        if (value['id'] === addressId) { return true; }
        else { return false; }
      });
      this.addressArr.splice(index, 1);

      // let index2 = this.addressArrOfStoreList.findIndex((value) => {
      //   if (value['id'] === addressId) { return true; }
      //   else { return false; }
      // });
      // this.addressArrOfStoreList.splice(index2, 1);
      return jo['data'];
    }));
  }

  getAddressAmount() {
    return this.addressArr.length;
  }

  saveCurrentAddress(address) {
    this.currentAddress = address;
  }


  calAddressesDistance(addresses, store: Store): Array<any> {
    let arr = addresses;

    if(!store) return arr;
    const couriers: any[] = store['couriers'];
    let radius;
    if (couriers && couriers.length != 0) {
      radius = Math.max.apply(Math, couriers.map((o) => o.radius / 1000)) || 5;
    }
    else { radius = 5; }
    console.log('calAddressesDistance with radius: ', radius);

    arr.map((a, i) => {

      let distance = this.calDistance(a.latitude, a.longitude, store);
      console.log('距离', distance);
      // arr[i]['beyond'] = distance > radius; // 超出5公里
      arr[i]['distance'] = distance;
    });
    return arr;
  }
  calDistance(lat2, lon2, s, unit = 'K') {
    if (!s || !s.location || !s.location.latitude || !s.location.longitude) return;
    let lat1 = s.location.latitude;
    let lon1 = s.location.longitude;
    // console.log('维度', lat1, lon1, lat2, lon2);
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    else {
      let radlat1 = Math.PI * lat1 / 180;
      let radlat2 = Math.PI * lat2 / 180;
      let theta = lon1 - lon2;
      let radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') { dist = dist * 1.609344; }
      if (unit === 'N') { dist = dist * 0.8684; }
      return dist;
    }
  }
  // bind the attr in address-dialog-ridio is what ,this filter function using attr is what ;
  findAddress(id): Address {
    return this.addressArr.find(address => address['id'] === id);
  }
  findPickupAddress(id) {
    return this.pickupArr.find(stroeLocation => stroeLocation['id'] === id);
  }

  // get the local delivery address from the addressArr
  findDefaultAddress(addresses) {
    this.isDefault = true;
    if (addresses.length > 0) {
      // this.currentAddress = addresses[0];
      return addresses[0];
    } else {
      return null;
    }
  }

  // pick up
  setPickup(bool: boolean) {
    this.isPickup = bool;
    if (bool && this.pickupArr.length === 0) {
      this.initPickupArr();
    }
  }
  checkMinimumCharge(courier, total) {
    return courier.minimum > total;
  }
  initPickupArr() {
    // todo call api to get pick up address
    // now hardcode current store
    this.pickupArr = [];
    if (this.orderManager.store && this.orderManager.store.location) {
      this.pickupArr.push(this.orderManager.store.location);
    } else {
      console.warn('store data is not exsiting location')
    }
  }

  getPickupAddress() {
    return this.isPickup ? (this.pickupAddress || null) : false;
  }

  setPickupAddress(obj) {
    this.pickupAddress = obj;
    this.isDefault = false;
    this.currentAddress = null;
  }

  // return: if select delivery address and pick up address
  isSetAddress(): boolean {
    return this.currentAddress || this.pickupAddress;
  }

  setIsChanged(bool) {
    this.isChanged = bool;
  }

  // reInit the pickupAddress translation because of the lang change
  reInitPickupAddressBecauseOfLangChanged(store) {
    if (!store || !store.location) return;
    console.log("reInitPickupAddressBecauseOfLangChanged");
    this.pickupArr = [];
    this.pickupArr.push(store.location);
    if (this.isPickup) {
      // 改变语言的时候 isPickup = true, need to chang the corresponding pickupAddress
      console.log('this.pickupAddress.id', this.pickupAddress);

      if (this.pickupAddress && this.pickupAddress['id']) {
        let newLangPickupaddress = this.findPickupAddress(this.pickupAddress['id']);
        this.setPickupAddress(newLangPickupaddress);
      }
    }
  }

  setPriceRulesOfCurrentAddress(rules) {
    if (!rules) return;
    this.priceRulesOfCurrentAddress = rules;
  }

  getMinimum(): number {
    if (this.priceRulesOfCurrentAddress && this.priceRulesOfCurrentAddress[0] && this.priceRulesOfCurrentAddress[0].minimum) {
      return this.priceRulesOfCurrentAddress[0].minimum;
    } else {
      return 0;
    }
  }

  clear() {
    this.currentCourier = null;
    this.note = '';
  }

  clearAll() {
    // logout
    this.addressArr = [];
    // this.addressArrOfStoreList = [];
    this.isPickup = false;
    this.currentAddress = null; // save the order using takeout-address
    this.couriers = [];
    this.currentCourier = null;
    this.pickupAddress = null;

  }

  getCouriers(storeId, country, addressId) {
    let url = '/api/v1/shipping/availability.json';

    const params = {};
    params['storeId'] = storeId;
    params['country'] = country;
    if (addressId) {
      params['addressId'] = addressId;
    }

    const aq = this.aq;
    aq.url = url;
    aq.params = params;

    return aq.getJson().pipe(map(jo => {
      // console.log('getCouriers jo', jo);
      return jo['data'];
    }));
  }

}
