import {Component, Injector, OnInit, Input} from '@angular/core';
import {MemberService} from '../../../core/services/member.service';

import {Brand, Member} from 'aigens-ng-core';
import {MobileBasePage} from '../../../core/base/mobile-base-page';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    confirmPasswordFormatValidator,
    getCountryCallingCode,
    isEmailValid,
    isPhoneValid,
    passwordFormatValidator,
    findCountryByCodes
} from '../../../shared/utilities/checkFormatTool';


declare var SmsReceiver;

@Component({
    selector: 'page-reset-password',
    templateUrl: 'reset-password-page.html',
    styleUrls: ['reset-password-page.scss']
})
export class ResetPasswordPage extends MobileBasePage implements OnInit {
    resetPasswordForm: FormGroup;
    email: string;
    phone: string;
    oldPassword: AbstractControl;
    password: AbstractControl;
    confirmPassword: AbstractControl;

    member: Member;
    submited = false;
    sent = false;
    reSent = false;
    basis = 180;
    countDown = this.basis;
    countryCallingCode: string;
    changePhoneCounter = 0;
    token: string;
    isForgotPassword = false;
    mode = 'email';

    username: string;

    brand: Brand;
    hasCrmId = false;

    // component parmas
    @Input() hideHeader: boolean = false;
    @Input() isForgotPassword_c: boolean;
    @Input() brand_c: Brand; 
    @Input() username_c: string; 
    @Input() countryCallingCode_c: string; 

    

    constructor(injector: Injector, public formBuilder: FormBuilder, public memberService: MemberService) {
        super(injector);
        // this.navCtrl.remove(1, 2);

    }

    initResetPage(){
        this.isForgotPassword = this.getNavParams('isForgotPassword');
        console.log("ResetPasswordPage");
        
        if (this.isForgotPassword_c) {
            this.isForgotPassword = this.isForgotPassword_c;
        }
        this.creatForm();

        this.brand = this.getNavParams('brand');
        if (this.brand_c) {
            this.brand = this.brand_c;
        }
        if (this.brand) {
            this.hasCrmId = this.brand['crmId'] ? true : false;
            if (this.hasCrmId) {
                this.mode = 'email';
            }
        }

        this.username = this.getNavParams('username');
        if (this.username_c) {
            this.username = this.username_c;
        }
        if (this.username) {
            this.email = this.username;
            if (!isEmailValid(this.email)) {
                this.email = '';
                if (this.username.match(/^\d+$/)) {
                    this.phone = this.username;
                    this.mode = 'phone';
                    let countryCallingCode = this.getNavParams('countryCallingCode');
                    if (this.countryCallingCode_c) {
                        countryCallingCode = this.countryCallingCode_c;
                    }
                    if (countryCallingCode) {
                        this.countryCallingCode = countryCallingCode;
                    }
                }
            }
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.initResetPage();
    }

    selectMode(type: string) {
        this.mode = type;
    }

    // TODO
    showCountryCallingCode(){
        return getCountryCallingCode();
    }
    requestResetCode() {
        if (this.mode === 'phone') {
            this.sendSms();
        } else {
            this.sendEmail();
        }
    }

    sendEmail() {

        if (!isEmailValid(this.email)) {
            this.showAlert('', this.t.instant('pages.verify-registration.invalid-email'));
            return;
        }

        this.countDown = this.basis;
        this.loading(true);
        this.memberApiService.requestResetCode(this.email, 'email').subscribe((data) => {
            this.loading(false);
            this.sent = true;
        }, (err) => {
            this.loading(false);
            this.showError(err);
        });

    }

    sendSms() {

        if (!this.countryCallingCode) {
            this.countryCallingCode = getCountryCallingCode();
        }
        if (!isPhoneValid(this.phone, findCountryByCodes(this.countryCallingCode))) {
            this.showAlert('', this.t.instant('pages.verify-registration.invalid-phone'));
            return;
        }
        if (this.changePhoneCounter <= 1) {
            this.countDown = this.basis;
            this.loading(true);
            this.memberApiService.requestResetCode(this.phone, 'phone', this.countryCallingCode).subscribe((data) => {
                this.loading(false);
                this.sent = true;
            }, (err) => {
                this.loading(false);
                this.showError(err);
            });

        } else {
            this.sent = true;
            this.countDown = this.basis * this.changePhoneCounter;
        }
        this.changePhoneCounter++;
    }

    clickReSend() {
        if (this.countDown <= 0 || this.mode === 'email') {
            this.requestResetCode();
            if (this.mode === 'phone') {
                this.changePhoneCounter--;
                this.countDown = this.basis;

            }
        }
    }

    verify() {
        this.loading(true);
        const address = this.mode === 'phone' ? this.phone : this.email;

        if (!this.countryCallingCode && this.mode === 'phone') {
            this.countryCallingCode = getCountryCallingCode();
        } else if (this.mode !== 'phone') {
            this.countryCallingCode = null;
        }

        this.memberApiService.verifyResetCode(this.mode, address, this.token, this.countryCallingCode).subscribe((data) => {
            this.loading(false);
            this.submited = true;
            this.token = data['token'];
        }, (err) => {
            this.loading(false);
            this.showError(err);
        });
    }

    resetPassword() {
        this.loading(true);
        if (this.isForgotPassword) {
            this.memberApiService.resetPassword(this.password.value, this.confirmPassword.value, this.token).subscribe((data) => {
                this.loading(false);
                this.showAlert('', this.t.instant('pages.login.reset-password-success'), [{
                    text: this.t.instant('buttons.ok'),
                    handler: () => {
                        this.navigationService.popPage();
                    }
                }]);
            }, (err) => {
                this.loading(false);
                this.showError(err);
            });
        } else {
            // To add resetPassword function
            this.memberApiService.resetPasswordWithOldPassword(this.oldPassword.value, this.password.value, this.confirmPassword.value).subscribe((data) => {
                this.loading(false);
                this.showAlert('', this.t.instant('pages.login.reset-password-success'), [{
                    text: this.t.instant('buttons.ok'),
                    handler: () => {
                        this.navigationService.popPage();
                    }
                }]);
            }, (err) => {
                this.loading(false);
                this.showError(err);
            });
        }
    }

    // 請輸入你嘅驗證碼XXXXXX，登記成為新會員！
    handleAndroidSms(field: string) {
        if (!field) {
            return;
        }
        const num = field.replace(/[^0-9]/ig, '');
        // console.log('num: ', num);
        if (!isNaN(parseInt(num)) && num.length >= 6) {
            this.token = num.substr(0, 6);
        }

    }


    onRefreshUI() {
        if (this.mode === 'phone' && this.sent && this.countDown > 0) {
            this.countDown--;
        }
    }

    getUIRefreshInterval(): number {
        return this.isForgotPassword ? 1000 : 0;
    }

    creatForm() {
        if (this.isForgotPassword) {
            this.resetPasswordForm = this.formBuilder.group({
                Password: [],
                Confirm_Password: []
            });

        } else {
            this.resetPasswordForm = this.formBuilder.group({
                Old_Password: [],
                Password: [],
                Confirm_Password: []
            });

            this.oldPassword = this.resetPasswordForm.get('Old_Password');
            this.oldPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));
        }

        this.password = this.resetPasswordForm.get('Password');
        this.password.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));

        this.confirmPassword = this.resetPasswordForm.get('Confirm_Password');
        this.confirmPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), confirmPasswordFormatValidator]));
        // this.gender = this.registrationForm.get('Gender');
        // this.birthday = this.registrationForm.get('Birthday');

        // this.firstName = this.registrationForm.get('First_Name'); this.firstName.setValidators(Validators.compose([Validators.required, firstNameFormatValidator]));

        // this.lastName = this.registrationForm.get('Last_Name');
        // this.lastName.setValidators(Validators.compose([Validators.required, lastNameFormatValidator]));

    }

    async clickResetPassword() {
        // console.log('clickRegister');
        if (this.resetPasswordForm.valid) {
            console.log('check pass ....');
            this.resetPassword();

        } else {
            const controlsKeys = Object.keys(this.resetPasswordForm.controls);
            let hasError = false;
            let errMessage = '';
            const newLine = `\r\n`;

            for (let i = 0; i < controlsKeys.length; i++) {
                const control: AbstractControl = this.resetPasswordForm.get(controlsKeys[i]);

                if (control.errors) {
                    hasError = true;

                    // console.log('controlsKeys[i]',controlsKeys[i]);   0:"firstName"  1:"lastName"  2:"email"  3:"phoneNumber"  4:"gender"  5:"birthday"  6:"password"
                    if (control.hasError('required')) {
                        // 必須填寫的

                        // errMessage += this.translate.instant(`pages.registration.messageInvaild${controlsKeys[i]}Required`);
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' is required';
                        errMessage += newLine;

                    } else if (control.hasError('minlength')) {
                        // password最少8位
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' has at least 8 characters with at least one letter and one number';
                        errMessage += newLine;
                    } else if (control.hasError('invalid password format')) {
                        /// password必須包含一個字母和一個數字
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' has at least 8 characters with at least one letter and one number';
                        errMessage += newLine;
                    } else if (control.hasError('equalTo')) {
                        /// password必須包含一個字母和一個數字
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += 'Confirm password is not equal to password';
                        errMessage += newLine;
                    }

                }
            }


            if (hasError && errMessage.length > 0) {
                const errAlert = await this.alertController.create({
                    // message: this.translate.instant("pages.registration.noticeAlertTitle"),
                    message: errMessage,

                    // buttons: [this.translate.instant("pages.registration.ok")],
                    buttons: ['Confirm'],
                    cssClass: 'alert-counter'/*app.scss */

                });
                errAlert.present();
            }


        }

    }

    backButtonClick() {
        this.googleAnalyticEventTrack('back');
        // if (!this.isForgotPassword) {
        //     this.popToRoot();
        //     return;
        // }

        if (this.submited && this.sent) {
            super.backButtonClick();
            return;
        }

        if (this.submited) {
            this.submited = false;
            return;
        }
        if (this.sent) {
            this.sent = false;
            return;
        }
        super.backButtonClick();
    }


}
