import {Injectable} from '@angular/core';
import {Brand, Store} from 'aigens-ng-core';
import {ModalController} from '@ionic/angular';
import {PopupDialog} from '../../dialogs/popup/popup';
import {ConfigService} from './config.service';
import {NavigationService} from './navigation.service';
import {ActivatedRoute} from '@angular/router';
import {MemberService} from './member.service';
import { SystemConfigService } from './systemConfig.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    isFirstEnterByodSplash = true;
    isSelectedLanguage = false;
    constructor(public modalController: ModalController, public memberService: MemberService,
        public configService: ConfigService, public navigationService: NavigationService,
         public route: ActivatedRoute,public systemConfigService:SystemConfigService) {
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }

    async showPopup(store: Store, popupLocation: string, dismissCallback?: Function) {
        if (!store || !store.brand || !store.brand.ads) {
            console.warn('input store has no popup', store);
            return;
        }
        let ads = store.brand['ads'];
        let popupKey = store.id + popupLocation;
        let params = { store };
        return await this.adsPopUp(ads, params, popupKey, popupLocation, dismissCallback);
    }

    async showPopup_Court(brand: Brand, courtId, popupLocation: string, dismissCallback?: Function) {
        if (!brand || !brand['ads']) {
            console.warn('input store has no popup', brand);
            return;
        }
        let ads = brand['ads'];
        let popupKey = brand.id + popupLocation;
        let params = { brand: brand, courtId }
        return await this.adsPopUp(ads, params, popupKey, popupLocation, dismissCallback);
    }

    async adsPopUp(ads, params, popupKey, popupLocation: string, dismissCallback?: Function) {
        let canShow = this.checkAdTodayCanShow(popupKey);
        let isMember = !!this.memberService.getMember();
        let adsToShow = ads.filter(ad => {
            if (ad.type !== 'popup' && ad.type !== 'slider')
                return false;
            if (!ad.images['default'] && (!ad.desc || !ad.desc.startsWith('http')))
                return false;
            if (ad.users && ad.users.length > 0) {
                if (ad.users.indexOf('guest') === -1 && !isMember)
                    return false;
                if (ad.users.indexOf('member') === -1 && isMember)
                    return false;
            }
            if ((ad['stage'] !== popupLocation) && (ad.stages && ad.stages.length > 0 && ad.stages.indexOf(popupLocation) === -1))
                return false;
            return canShow || ad['repeats'];
        });

        if (adsToShow.length > 0) {


            const input = {...params, ads: adsToShow};
            let modal = await this.modalController.create(
                {
                    component: PopupDialog,
                    componentProps: input,
                    showBackdrop: true,
                    backdropDismiss: true,
                    cssClass: 'no-bgColor'
                });

            modal.onDidDismiss().then((data) => dismissCallback(data));
            modal.present().then(() => {
                // save popup end time
                this.saveTodayEndTime(popupKey);
            });
        }
    }

    /**********  for , 根據key,判斷是否在今天內顯示過 start  ********/
    /// key = storeid  + ad.stage
    private saveTodayEndTime(key: string) {
        if (!key) return;
        let endTime = new Date().setHours(23, 59, 59, 0);
        // this.configService.putPreference(key, endTime);
        this.configService.setCookie(key, String(endTime));
    }

    private checkAdTodayCanShow(key: string): boolean {
        let canshow = this.systemConfigService.showCourtBrandAdsEverytime;
        if (canshow) {
            return canshow;
        }
        if (!key) return;
        let nowTime = new Date().getTime();
        let value = this.configService.getCookie(key) || '0';
        return nowTime > Number(value);
    }
}
