
    let phoneListZh = [
        { value: '852', text: '香港(+852)' },
        { value: '86', text: '中国(+86)' },
        { value: '886', text: '台湾(+886)' },
        { value: '93', text: '阿富汗(+93)' },
        { value: '355', text: '阿尔巴尼亚(+355)' },
        { value: '213', text: '阿尔及尼亚(+213)' },
        { value: '376', text: '安道尔(+376)' },
        { value: '1264', text: '安圭拉岛(英)(+1264)' },
        { value: '244', text: '安哥拉(+244)' },
        { value: '1268', text: '安提瓜和巴布达(+1268)' },
        { value: '54', text: '阿根廷(+54)' },
        { value: '374', text: '亚美尼亚(+374)' },
        { value: '247', text: '阿森松(英)(+247)' },
        { value: '61', text: '澳大利亚(+61)' },
        { value: '43', text: '奥地利(+43)' },
        { value: '994', text: '阿塞拜疆(+994)' },
        { value: '1242', text: '巴哈马国(+1242)' },
        { value: '973', text: '巴林(+973)' },
        { value: '880', text: '孟加拉国(+880)' },
        { value: '1246', text: '巴巴多斯(+1246)' },


        { value: '375', text: '白俄罗斯(+375)' },
        { value: '32', text: '比利时(+32)' },
        { value: '501', text: '伯利兹(+501)' },
        { value: '229', text: '贝宁(+229)' },
        { value: '1441', text: '百慕大群岛(英)(+1441)' },
        { value: '591', text: '玻利维亚(+591)' },
        { value: '267', text: '博茨瓦纳(+267)' },
        { value: '55', text: '巴西(+55)' },
        { value: '673', text: '文莱(+673)' },
        { value: '359', text: '保加利亚(+359)' },
        { value: '226', text: '布基纳法索(+226)' },
        { value: '95', text: '缅甸(+95)' },
        { value: '257', text: '布隆迪(+257)' },
        { value: '237', text: '喀麦隆(+237)' },
        { value: '1', text: '美国/加拿大(+1)' },
        { value: '1345', text: '开曼群岛(英)(+1345)' },
        { value: '236', text: '中非(+236)' },
        { value: '235', text: '乍得(+235)' },
        { value: '56', text: '智利(+56)' },
        { value: '57', text: '哥伦比亚(+57)' },
        { value: '242', text: '刚果(+242)' },
        { value: '682', text: '科克群岛(新)(+682)' },
        { value: '506', text: '哥斯达黎加(+506)' },
        { value: '53', text: '古巴(+53)' },
        { value: '357', text: '塞浦路斯(+357)' },
        { value: '420', text: '捷克共和国(+420)' },
        { value: '45', text: '丹麦(+45)' },
        { value: '253', text: '吉布提(+253)' },
        { value: '1890', text: '多米尼加共和国(+1890)' },
        { value: '593', text: '厄瓜多尔(+593)' },
        { value: '20', text: '埃及(+20)' },
        { value: '503', text: '萨尔瓦多(+503)' },
        { value: '372', text: '爱沙尼亚(+372)' },
        { value: '251', text: '埃塞俄比亚(+251)' },
        { value: '679', text: '斐济(+679)' },
        { value: '358', text: '芬兰(+358)' },
        { value: '33', text: '法国(+33)' },
        { value: '594', text: '法属圭亚那(+594)' },
        { value: '241', text: '加蓬(+241)' },
        { value: '220', text: '冈比亚(+220)' },
        { value: '995', text: '格鲁吉亚(+995)' },
        { value: '49', text: '德国(+49)' },
        { value: '233', text: '加纳(+233)' },
        { value: '350', text: '直布罗陀(英)(+350)' },
        { value: '30', text: '希腊(+30)' },
        { value: '1809', text: '格林纳达(+1809)' },
        { value: '1671', text: '关岛 (+1671)' },
        { value: '502', text: '危地马拉(+502)' },
        { value: '224', text: '几内亚 (+224)' },
        { value: '592', text: '圭亚那 (+592)' },
        { value: '509', text: '海地(+509)' },
        { value: '504', text: '洪都拉斯(+504)' },
        { value: '36', text: '匈牙利(+36)' },
        { value: '354', text: '冰岛(+354)' },
        { value: '91', text: '印度(+91)' },
        { value: '62', text: '印度尼西亚(+62)' },
        { value: '98', text: '伊朗(+98)' },
        { value: '964', text: '伊拉克(+964)' },
        { value: '353', text: '爱尔兰(+353)' },
        { value: '972', text: '以色列(+972)' },
        { value: '39', text: '意大利(+39)' },
        { value: '225', text: '科特迪瓦(+225)' },
        { value: '1876', text: '牙买加(+1876)' },
        { value: '81', text: '日本(+81)' },
        { value: '962', text: '约旦(+962)' },
        { value: '855', text: '柬埔寨(+855)' },
        { value: '327', text: '哈萨克斯坦 (+327)' },
        { value: '254', text: '肯尼亚(+254)' },
        { value: '82', text: '韩国(+82)' },
        { value: '965', text: '科威特(+965)' },
        { value: '331', text: '吉尔吉斯斯坦(+331)' },
        { value: '856', text: '老挝(+856)' },
        { value: '371', text: '拉脱维亚(+371)' },
        { value: '266', text: '黎巴嫩(+266)' },
        { value: '231', text: '利比里亚(+231)' },
        { value: '218', text: '利比亚(+218)' },
        { value: '423', text: '列支敦士登(+423)' },
        { value: '370', text: '立陶宛(+370)' },
        { value: '352', text: '卢森堡(+352)' },
        { value: '853', text: '澳门(+853)' },
        { value: '261', text: '马达加斯加(+261)' },
        { value: '265', text: '马拉维(+265)' },
        { value: '60', text: '马来西亚(+60)' },
        { value: '960', text: '马尔代夫(+960)' },
        { value: '223', text: '马里(+223)' },
        { value: '356', text: '马耳他(+356)' },
        { value: '1670', text: '马里亚纳群岛(+1670)' },
        { value: '230', text: '毛里求斯(+230)' },
        { value: '52', text: '墨西哥(+52)' },
        { value: '373', text: '摩尔多瓦共和国(+373)' },
        { value: '377', text: '摩纳哥(+377)' },
        { value: '976', text: '蒙古(+976)' },
        { value: '1664', text: '蒙特塞拉特(+1664)' },
        { value: '212', text: '摩洛哥(+212)' },
        { value: '258', text: '莫桑比克(+258)' },
        { value: '264', text: '纳米尼亚(+264)' },
        { value: '674', text: '瑙鲁(+674)' },
        { value: '977', text: '尼泊尔(+977)' },
        { value: '599', text: '荷属安的列斯(+599)' },
        { value: '31', text: '荷兰(+31)' },
        { value: '64', text: '新西兰(+64)' },
        { value: '505', text: '尼加拉瓜(+505)' },
        { value: '227', text: '尼日尔(+227)' },
        { value: '234', text: '尼日利亚(+234)' },
        { value: '850', text: '朝鲜(+850)' },
        { value: '47', text: '挪威(+47)' },
        { value: '968', text: '阿曼(+968)' },
        { value: '92', text: '巴基斯坦(+92)' },
        { value: '507', text: '巴拿马(+507)' },
        { value: '675', text: '巴布亚新几内亚(+675)' },
        { value: '595', text: '巴拉圭(+595)' },
        { value: '51', text: '秘鲁(+51)' },
        { value: '63', text: '菲律宾(+63)' },
        { value: '48', text: '波兰(+48)' },
        { value: '689', text: '法属波利尼西亚(+689)' },
        { value: '351', text: '葡萄牙(+351)' },
        { value: '1787', text: '波多黎各(+1787)' },
        { value: '974', text: '卡塔尔(+974)' },
        { value: '262', text: '留尼汪(+262)' },
        { value: '40', text: '罗马尼亚(+40)' },
        { value: '7', text: '俄罗斯(+7)' },
        { value: '1758', text: '圣卢西亚(+1758)' },
        { value: '1784', text: '圣文森特(+1784)' },
        { value: '684', text: '东萨摩亚(+684)' },
        { value: '685', text: '西萨摩亚(+685)' },
        { value: '378', text: '圣马力诺 (+378)' },
        { value: '239', text: '圣多美和普林西比(+239)' },
        { value: '966', text: '沙特阿拉伯(+966)' },
        { value: '221', text: '塞内加尔(+221)' },
        { value: '248', text: '塞舌尔(+248)' },
        { value: '232', text: '塞拉利(+232)' },
        { value: '65', text: '新加坡(+65)' },
        { value: '421', text: '斯洛伐克(+421)' },
        { value: '386', text: '斯洛文尼亚(+386)' },
        { value: '677', text: '所罗门群岛(+677)' },
        { value: '252', text: '索马里(+252)' },
        { value: '27', text: '南非(+27)' },
        { value: '34', text: '西班牙(+34)' },
        { value: '94', text: '斯里兰卡(+94)' },
        { value: '1758', text: '圣卢西亚(+1758)' },
        { value: '1784', text: '圣文森特和格林纳丁斯(+1784)' },
        { value: '249', text: '苏丹(+249)' },
        { value: '597', text: '苏里南(+597)' },
        { value: '268', text: '斯威士兰(+268)' },
        { value: '46', text: '瑞典(+46)' },
        { value: '41', text: '瑞士(+41)' },
        { value: '963', text: '叙利亚(+963)' },
        { value: '992', text: '塔吉克斯坦(+992)' },
        { value: '255', text: '坦桑尼亚(+255)' },
        { value: '66', text: '泰国(+66)' },
        { value: '228', text: '多哥(+228)' },
        { value: '676', text: '汤加(+676)' },
        { value: '1868', text: '特立尼达和多巴哥(+1868)' },
        { value: '216', text: '突尼斯(+216)' },
        { value: '90', text: '土耳其 (+90)' },
        { value: '993', text: '土库曼斯坦(+993)' },
        { value: '256', text: '乌干达(+256)' },
        { value: '380', text: '乌克兰(+380)' },
        { value: '971', text: '阿拉伯联合酋长国(+971)' },
        { value: '44', text: '英国(+44)' },
        { value: '598', text: '乌拉圭(+598)' },
        { value: '233', text: '乌兹别克斯坦(+233)' },
        { value: '58', text: '委内瑞拉(+58)' },
        { value: '84', text: '越南(+84)' },
        { value: '967', text: '也门(+967)' },
        { value: '381', text: '南斯拉夫(+381)' },
        { value: '263', text: '津巴布韦(+263)' },
        { value: '243', text: '刚果(+243)' },
        { value: '260', text: '赞比亚(+260)' }
    ];
    let phoneListEn = [
        { value: '852', text: 'Hong Kong (+852)' },
        { value: '86', text: 'China (+86)' },
        { value: '886', text: 'Taiwan (+886)' },
        { value: '93', text: 'Afghanistan (+93)' },
        { value: '355', text: 'Albania (+355)' },
        { value: '213', text: 'Algeria (+213)' },
        { value: '376', text: 'Andorra (+376)' },
        { value: '1264', text: 'Anguilla (+1264)' },
        { value: '244', text: 'Angola (+244)' },
        { value: '1268', text: 'Antigua and Barbuda (+1268)' },
        { value: '54', text: 'Argentina (+54)' },
        { value: '374', text: 'Armenia (+374)' },
        { value: '247', text: 'Ascension (+247)' },
        { value: '61', text: 'Australia (+61)' },
        { value: '43', text: 'Austria (+43)' },
        { value: '994', text: 'Azerbaijan (+994)' },
        { value: '1242', text: 'Bahamas (+1242)' },
        { value: '973', text: 'Bahrain (+973)' },
        { value: '880', text: 'Bangladesh (+880)' },
        { value: '1246', text: 'Barbados (+1246)' },
        { value: '375', text: 'Belarus (+375)' },
        { value: '32', text: 'Belgium (+32)' },
        { value: '501', text: 'Belize (+501)' },
        { value: '229', text: 'Benin (+229)' },
        { value: '1441', text: 'Bermuda Is. (+1441)' },
        { value: '591', text: 'Bolivia (+591)' },
        { value: '267', text: 'Botswana (+267)' },
        { value: '55', text: 'Brazil (+55)' },
        { value: '673', text: 'Brunei (+673)' },
        { value: '359', text: 'Bulgaria (+359)' },
        { value: '226', text: 'Burkina-faso (+226)' },
        { value: '95', text: 'Burma (+95)' },
        { value: '257', text: 'Burundi (+257)' },
        { value: '237', text: 'Cameroon (+237)' },
        { value: '1', text: 'America/Canada (+1)' },
        { value: '1345', text: 'Cayman Is. (+1345)' },
        { value: '236', text: 'Central African Republic (+236)' },
        { value: '235', text: 'Chad (+235)' },
        { value: '56', text: 'Chile (+56)' },
        { value: '57', text: 'Colombia (+57)' },
        { value: '242', text: 'Congo (+242)' },
        { value: '682', text: 'Cook Is. (+682)' },
        { value: '506', text: 'Costa Rica (+506)' },
        { value: '53', text: 'Cuba (+53)' },
        { value: '357', text: 'Cyprus (+357)' },
        { value: '420', text: 'Czech Republic (+420)' },
        { value: '45', text: 'Denmark (+45)' },
        { value: '253', text: 'Djibouti (+253)' },
        { value: '1890', text: 'Dominica Rep. (+1890)' },
        { value: '593', text: 'Ecuador (+593)' },
        { value: '20', text: 'Egypt (+20)' },
        { value: '503', text: 'EI Salvador (+503)' },
        { value: '372', text: 'Estonia (+372)' },
        { value: '251', text: 'Ethiopia (+251)' },
        { value: '679', text: 'Fiji (+679)' },
        { value: '358', text: 'Finland (+358)' },
        { value: '33', text: 'France (+33)' },
        { value: '594', text: 'French Guiana (+594)' },
        { value: '241', text: 'Gabon (+241)' },
        { value: '220', text: 'Gambia (+220)' },
        { value: '995', text: 'Georgia (+995)' },
        { value: '49', text: 'Germany (+49)' },
        { value: '233', text: 'Ghana (+233)' },
        { value: '350', text: 'Gibraltar (+350)' },
        { value: '30', text: 'Greece (+30)' },
        { value: '1809', text: 'Grenada (+1809)' },
        { value: '1671', text: 'Guam (+1671)' },
        { value: '502', text: 'Guatemala (+502)' },
        { value: '224', text: 'Guinea (+224)' },
        { value: '592', text: 'Guyana (+592)' },
        { value: '509', text: 'Haiti (+509)' },
        { value: '504', text: 'Honduras (+504)' },
        { value: '36', text: 'Hungary (+36)' },
        { value: '354', text: 'Iceland (+354)' },
        { value: '91', text: 'India (+91)' },
        { value: '62', text: 'Indonesia (+62)' },
        { value: '98', text: 'Iran (+98)' },
        { value: '964', text: 'Iraq (+964)' },
        { value: '353', text: 'Ireland (+353)' },
        { value: '972', text: 'Israel (+972)' },
        { value: '39', text: 'Italy (+39)' },
        { value: '225', text: 'Ivory Coast (+225)' },
        { value: '1876', text: 'Jamaica (+1876)' },
        { value: '81', text: 'Japan (+81)' },
        { value: '962', text: 'Jordan (+962)' },
        { value: '855', text: 'Kampuchea (Cambodia) (+855)' },
        { value: '327', text: 'Kazakstan (+327)' },
        { value: '254', text: 'Kenya (+254)' },
        { value: '82', text: 'Korea (+82)' },
        { value: '965', text: 'Kuwait (+965)' },
        { value: '331', text: 'Kyrgyzstan (+331)' },
        { value: '856', text: 'Laos (+856)' },
        { value: '371', text: 'Latvia (+371)' },
        { value: '266', text: 'Lesotho (+266)' },
        { value: '231', text: 'Liberia (+231)' },
        { value: '218', text: 'Libya (+218)' },
        { value: '423', text: 'Liechtenstein (+423)' },
        { value: '370', text: 'Lithuania (+370)' },
        { value: '352', text: 'Luxembourg (+352)' },
        { value: '853', text: 'Macao (+853)' },
        { value: '261', text: 'Madagascar (+261)' },
        { value: '265', text: 'Malawi (+265)' },
        { value: '60', text: 'Malaysia (+60)' },
        { value: '960', text: 'Maldives (+960)' },
        { value: '223', text: 'Mali (+223)' },
        { value: '356', text: 'Malta (+356)' },
        { value: '1670', text: 'Mariana Is. (+1670)' },
        { value: '230', text: 'Mauritius (+230)' },
        { value: '52', text: 'Mexico (+52)' },
        { value: '373', text: 'Republic of Moldova (+373)' },
        { value: '377', text: 'Monaco (+377)' },
        { value: '976', text: 'Mongolia (+976)' },
        { value: '1664', text: 'Montserrat Is (+1664)' },
        { value: '212', text: 'Morocco (+212)' },
        { value: '258', text: 'Mozambique (+258)' },
        { value: '264', text: 'Namibia (+264)' },
        { value: '674', text: 'Nauru (+674)' },
        { value: '977', text: 'Nepal (+977)' },
        { value: '599', text: 'Netheriands Antilles (+599)' },
        { value: '31', text: 'Netherlands (+31)' },
        { value: '64', text: 'New Zealand (+64)' },
        { value: '505', text: 'Nicaragua (+505)' },
        { value: '227', text: 'Niger (+227)' },
        { value: '234', text: 'Nigeria (+234)' },
        { value: '850', text: 'North Korea (+850)' },
        { value: '47', text: 'Norway (+47)' },
        { value: '968', text: 'Oman (+968)' },
        { value: '92', text: 'Pakistan (+92)' },
        { value: '507', text: 'Panama (+507)' },
        { value: '675', text: 'Papua New Cuinea (+675)' },
        { value: '595', text: 'Paraguay (+595)' },
        { value: '51', text: 'Peru (+51)' },
        { value: '63', text: 'Philippines (+63)' },
        { value: '48', text: 'Poland (+48)' },
        { value: '689', text: 'French Polynesia (+689)' },
        { value: '351', text: 'Portugal (+351)' },
        { value: '1787', text: 'Puerto Rico (+1787)' },
        { value: '974', text: 'Qatar (+974)' },
        { value: '262', text: 'Reunion (+262)' },
        { value: '40', text: 'Romania (+40)' },
        { value: '7', text: 'Russia (+7)' },
        { value: '1758', text: 'Saint Lueia (+1758)' },
        { value: '1784', text: 'Saint Vincent (+1784)' },
        { value: '684', text: 'Samoa Eastern (+684)' },
        { value: '685', text: 'Samoa Western (+685)' },
        { value: '378', text: 'San Marino (+378)' },
        { value: '239', text: 'Sao Tome and Principe (+239)' },
        { value: '966', text: 'Saudi Arabia (+966)' },
        { value: '221', text: 'Senegal (+221)' },
        { value: '248', text: 'Seychelles (+248)' },
        { value: '232', text: 'Sierra Leone (+232)' },
        { value: '65', text: 'Singapore (+65)' },
        { value: '421', text: 'Slovakia (+421)' },
        { value: '386', text: 'Slovenia (+386)' },
        { value: '677', text: 'Solomon Is (+677)' },
        { value: '252', text: 'Somali (+252)' },
        { value: '27', text: 'South Africa (+27)' },
        { value: '34', text: 'Spain (+34)' },
        { value: '94', text: 'Sri Lanka (+94)' },
        { value: '1758', text: 'St.Lucia (+1758)' },
        { value: '1784', text: 'St.Vincent (+1784)' },
        { value: '249', text: 'Sudan (+249)' },
        { value: '597', text: 'Suriname (+597)' },
        { value: '268', text: 'Swaziland (+268)' },
        { value: '46', text: 'Sweden (+46)' },
        { value: '41', text: 'Switzerland (+41)' },
        { value: '963', text: 'Syria (+963)' },
        { value: '992', text: 'Tajikstan (+992)' },
        { value: '255', text: 'Tanzania (+255)' },
        { value: '66', text: 'Thailand (+66)' },
        { value: '228', text: 'Togo (+228)' },
        { value: '676', text: 'Tonga (+676)' },
        { value: '1868', text: 'Trinidad and Tobago (+1868)' },
        { value: '216', text: 'Tunisia (+216)' },
        { value: '90', text: 'Turkey (+90)' },
        { value: '993', text: 'Turkmenistan (+993)' },
        { value: '256', text: 'Uganda (+256)' },
        { value: '380', text: 'Ukraine (+380)' },
        { value: '971', text: 'United Arab Emirates (+971)' },
        { value: '44', text: 'United Kingdom (+44)' },
        { value: '598', text: 'Uruguay (+598)' },
        { value: '233', text: 'Uzbekistan (+233)' },
        { value: '58', text: 'Venezuela (+58)' },
        { value: '84', text: 'Vietnam (+84)' },
        { value: '967', text: 'Yemen (+967)' },
        { value: '381', text: 'Yugoslavia (+381)' },
        { value: '263', text: 'Zimbabwe (+263)' },
        { value: '243', text: 'Zaire (+243)' },
        { value: '260', text: 'Zambia (+260)' }
    ];
    
    let  countryNumbers = [

        { countryCode: 'HK', number: '852' },
        { countryCode: 'CN', number: '86' },
        { countryCode: 'TW', number: '886' },
        { countryCode: 'BD', number: '880' },
        { countryCode: 'BE', number: '32' },
        { countryCode: 'BF', number: '226' },
        { countryCode: 'BG', number: '359' },
        { countryCode: 'BA', number: '387' },
        { countryCode: 'BB', number: '1-246' },
        { countryCode: 'WF', number: '681' },
        { countryCode: 'BL', number: '590' },
        { countryCode: 'BM', number: '1-441' },
        { countryCode: 'BN', number: '673' },
        { countryCode: 'BO', number: '591' },
        { countryCode: 'BH', number: '973' },
        { countryCode: 'BI', number: '257' },
        { countryCode: 'BJ', number: '229' },
        { countryCode: 'BT', number: '975' },
        { countryCode: 'JM', number: '1-876' },
        // { countryCode: "BV", number: "" },
        { countryCode: 'BW', number: '267' },
        { countryCode: 'WS', number: '685' },
        { countryCode: 'BQ', number: '599' },
        { countryCode: 'BR', number: '55' },
        { countryCode: 'BS', number: '1-242' },
        { countryCode: 'JE', number: '44-1534' },
        { countryCode: 'BY', number: '375' },
        { countryCode: 'BZ', number: '501' },
        { countryCode: 'RU', number: '7' },
        { countryCode: 'RW', number: '250' },
        { countryCode: 'RS', number: '381' },
        { countryCode: 'TL', number: '670' },
        { countryCode: 'RE', number: '262' },
        { countryCode: 'TM', number: '993' },
        { countryCode: 'TJ', number: '992' },
        { countryCode: 'RO', number: '40' },
        { countryCode: 'TK', number: '690' },
        { countryCode: 'GW', number: '245' },
        { countryCode: 'GU', number: '1-671' },
        { countryCode: 'GT', number: '502' },
        // { countryCode: "GS", number: "" },
        { countryCode: 'GR', number: '30' },
        { countryCode: 'GQ', number: '240' },
        { countryCode: 'GP', number: '590' },
        { countryCode: 'JP', number: '81' },
        { countryCode: 'GY', number: '592' },
        { countryCode: 'GG', number: '44-1481' },
        { countryCode: 'GF', number: '594' },
        { countryCode: 'GE', number: '995' },
        { countryCode: 'GD', number: '1-473' },
        { countryCode: 'GB', number: '44' },
        { countryCode: 'GA', number: '241' },
        { countryCode: 'SV', number: '503' },
        { countryCode: 'GN', number: '224' },
        { countryCode: 'GM', number: '220' },
        { countryCode: 'GL', number: '299' },
        { countryCode: 'GI', number: '350' },
        { countryCode: 'GH', number: '233' },
        { countryCode: 'OM', number: '968' },
        { countryCode: 'TN', number: '216' },
        { countryCode: 'JO', number: '962' },
        { countryCode: 'HR', number: '385' },
        { countryCode: 'HT', number: '509' },
        { countryCode: 'HU', number: '36' },
        { countryCode: 'HN', number: '504' },
        // { countryCode: "HM", number: " " },
        { countryCode: 'VE', number: '58' },
        { countryCode: 'PR', number: '1-787' },
        { countryCode: 'PR', number: '1-939' },
        { countryCode: 'PS', number: '970' },
        { countryCode: 'PW', number: '680' },
        { countryCode: 'PT', number: '351' },
        { countryCode: 'SJ', number: '47' },
        { countryCode: 'PY', number: '595' },
        { countryCode: 'IQ', number: '964' },
        { countryCode: 'PA', number: '507' },
        { countryCode: 'PF', number: '689' },
        { countryCode: 'PG', number: '675' },
        { countryCode: 'PE', number: '51' },
        { countryCode: 'PK', number: '92' },
        { countryCode: 'PH', number: '63' },
        { countryCode: 'PN', number: '870' },
        { countryCode: 'PL', number: '48' },
        { countryCode: 'PM', number: '508' },
        { countryCode: 'ZM', number: '260' },
        { countryCode: 'EH', number: '212' },
        { countryCode: 'EE', number: '372' },
        { countryCode: 'EG', number: '20' },
        { countryCode: 'ZA', number: '27' },
        { countryCode: 'EC', number: '593' },
        { countryCode: 'IT', number: '39' },
        { countryCode: 'VN', number: '84' },
        { countryCode: 'SB', number: '677' },
        { countryCode: 'ET', number: '251' },
        { countryCode: 'SO', number: '252' },
        { countryCode: 'ZW', number: '263' },
        { countryCode: 'SA', number: '966' },
        { countryCode: 'ES', number: '34' },
        { countryCode: 'ER', number: '291' },
        { countryCode: 'ME', number: '382' },
        { countryCode: 'MD', number: '373' },
        { countryCode: 'MG', number: '261' },
        { countryCode: 'MF', number: '590' },
        { countryCode: 'MA', number: '212' },
        { countryCode: 'MC', number: '377' },
        { countryCode: 'UZ', number: '998' },
        { countryCode: 'MM', number: '95' },
        { countryCode: 'ML', number: '223' },
        { countryCode: 'MO', number: '853' },
        { countryCode: 'MN', number: '976' },
        { countryCode: 'MH', number: '692' },
        { countryCode: 'MK', number: '389' },
        { countryCode: 'MU', number: '230' },
        { countryCode: 'MT', number: '356' },
        { countryCode: 'MW', number: '265' },
        { countryCode: 'MV', number: '960' },
        { countryCode: 'MQ', number: '596' },
        { countryCode: 'MP', number: '1-670' },
        { countryCode: 'MS', number: '1-664' },
        { countryCode: 'MR', number: '222' },
        { countryCode: 'IM', number: '44-1624' },
        { countryCode: 'UG', number: '256' },
        { countryCode: 'TZ', number: '255' },
        { countryCode: 'MY', number: '60' },
        { countryCode: 'MX', number: '52' },
        { countryCode: 'IL', number: '972' },
        { countryCode: 'FR', number: '33' },
        { countryCode: 'IO', number: '246' },
        { countryCode: 'SH', number: '290' },
        { countryCode: 'FI', number: '358' },
        { countryCode: 'FJ', number: '679' },
        { countryCode: 'FK', number: '500' },
        { countryCode: 'FM', number: '691' },
        { countryCode: 'FO', number: '298' },
        { countryCode: 'NI', number: '505' },
        { countryCode: 'NL', number: '31' },
        { countryCode: 'NO', number: '47' },
        { countryCode: 'NA', number: '264' },
        { countryCode: 'VU', number: '678' },
        { countryCode: 'NC', number: '687' },
        { countryCode: 'NE', number: '227' },
        { countryCode: 'NF', number: '672' },
        { countryCode: 'NG', number: '234' },
        { countryCode: 'NZ', number: '64' },
        { countryCode: 'NP', number: '977' },
        { countryCode: 'NR', number: '674' },
        { countryCode: 'NU', number: '683' },
        { countryCode: 'CK', number: '682' },
        // { countryCode: "XK", number: "" },
        { countryCode: 'CI', number: '225' },
        { countryCode: 'CH', number: '41' },
        { countryCode: 'CO', number: '57' },
        { countryCode: 'CM', number: '237' },
        { countryCode: 'CL', number: '56' },
        { countryCode: 'CC', number: '61' },
        { countryCode: 'CA', number: '1' },
        { countryCode: 'CG', number: '242' },
        { countryCode: 'CF', number: '236' },
        { countryCode: 'CD', number: '243' },
        { countryCode: 'CZ', number: '420' },
        { countryCode: 'CY', number: '357' },
        { countryCode: 'CX', number: '61' },
        { countryCode: 'CR', number: '506' },
        { countryCode: 'CW', number: '599' },
        { countryCode: 'CV', number: '238' },
        { countryCode: 'CU', number: '53' },
        { countryCode: 'SZ', number: '268' },
        { countryCode: 'SY', number: '963' },
        { countryCode: 'SX', number: '599' },
        { countryCode: 'KG', number: '996' },
        { countryCode: 'KE', number: '254' },
        { countryCode: 'SS', number: '211' },
        { countryCode: 'SR', number: '597' },
        { countryCode: 'KI', number: '686' },
        { countryCode: 'KH', number: '855' },
        { countryCode: 'KN', number: '1-869' },
        { countryCode: 'KM', number: '269' },
        { countryCode: 'ST', number: '239' },
        { countryCode: 'SK', number: '421' },
        { countryCode: 'KR', number: '82' },
        { countryCode: 'SI', number: '386' },
        { countryCode: 'KP', number: '850' },
        { countryCode: 'KW', number: '965' },
        { countryCode: 'SN', number: '221' },
        { countryCode: 'SM', number: '378' },
        { countryCode: 'SL', number: '232' },
        { countryCode: 'SC', number: '248' },
        { countryCode: 'KZ', number: '7' },
        { countryCode: 'KY', number: '1-345' },
        { countryCode: 'SG', number: '65' },
        { countryCode: 'SE', number: '46' },
        { countryCode: 'SD', number: '249' },
        { countryCode: 'DO', number: '1-809' },
        { countryCode: 'DO', number: '1-829' },
        { countryCode: 'DO', number: '1-829' },
        { countryCode: 'DM', number: '1-767' },
        { countryCode: 'DJ', number: '253' },
        { countryCode: 'DK', number: '45' },
        { countryCode: 'VG', number: '1-284' },
        { countryCode: 'DE', number: '49' },
        { countryCode: 'YE', number: '967' },
        { countryCode: 'DZ', number: '213' },
        { countryCode: 'US', number: '1' },
        { countryCode: 'UY', number: '598' },
        { countryCode: 'YT', number: '262' },
        { countryCode: 'UM', number: '1' },
        { countryCode: 'LB', number: '961' },
        { countryCode: 'LC', number: '1-758' },
        { countryCode: 'LA', number: '856' },
        { countryCode: 'TV', number: '688' },
        { countryCode: 'TT', number: '1-868' },
        { countryCode: 'TR', number: '90' },
        { countryCode: 'LK', number: '94' },
        { countryCode: 'LI', number: '423' },
        { countryCode: 'LV', number: '371' },
        { countryCode: 'TO', number: '676' },
        { countryCode: 'LT', number: '370' },
        { countryCode: 'LU', number: '352' },
        { countryCode: 'LR', number: '231' },
        { countryCode: 'LS', number: '266' },
        { countryCode: 'TH', number: '66' },
        // { countryCode: "TF", number: "" },
        { countryCode: 'TG', number: '228' },
        { countryCode: 'TD', number: '235' },
        { countryCode: 'TC', number: '1-649' },
        { countryCode: 'LY', number: '218' },
        { countryCode: 'VA', number: '379' },
        { countryCode: 'VC', number: '1-784' },
        { countryCode: 'AE', number: '971' },
        { countryCode: 'AD', number: '376' },
        { countryCode: 'AG', number: '1-268' },
        { countryCode: 'AF', number: '93' },
        { countryCode: 'AI', number: '1-264' },
        { countryCode: 'VI', number: '1-340' },
        { countryCode: 'IS', number: '354' },
        { countryCode: 'IR', number: '98' },
        { countryCode: 'AM', number: '374' },
        { countryCode: 'AL', number: '355' },
        { countryCode: 'AO', number: '244' },
        // { countryCode: "AQ", number: "" },
        { countryCode: 'AS', number: '1-684' },
        { countryCode: 'AR', number: '54' },
        { countryCode: 'AU', number: '61' },
        { countryCode: 'AT', number: '43' },
        { countryCode: 'AW', number: '297' },
        { countryCode: 'IN', number: '91' },
        { countryCode: 'AX', number: '358-18' },
        { countryCode: 'AZ', number: '994' },
        { countryCode: 'IE', number: '353' },
        { countryCode: 'ID', number: '62' },
        { countryCode: 'UA', number: '380' },
        { countryCode: 'QA', number: '974' },
        { countryCode: 'MZ', number: '258' }
    ];
    let transtions =  {
        "HK": {
            "value": "852",
            "textZh": "香港(+852)",
            "textEn": "Hong Kong (+852)"
        },
        "CN": {
            "value": "86",
            "textZh": "中国(+86)",
            "textEn": "China (+86)"
        },
        "TW": {
            "value": "886",
            "textZh": "台湾(+886)",
            "textEn": "Taiwan (+886)"
        },
        "BD": {
            "value": "880",
            "textZh": "孟加拉国(+880)",
            "textEn": "Bangladesh (+880)"
        },
        "BE": {
            "value": "32",
            "textZh": "比利时(+32)",
            "textEn": "Belgium (+32)"
        },
        "BF": {
            "value": "226",
            "textZh": "布基纳法索(+226)",
            "textEn": "Burkina-faso (+226)"
        },
        "BG": {
            "value": "359",
            "textZh": "保加利亚(+359)",
            "textEn": "Bulgaria (+359)"
        },
        "BN": {
            "value": "673",
            "textZh": "文莱(+673)",
            "textEn": "Brunei (+673)"
        },
        "BO": {
            "value": "591",
            "textZh": "玻利维亚(+591)",
            "textEn": "Bolivia (+591)"
        },
        "BH": {
            "value": "973",
            "textZh": "巴林(+973)",
            "textEn": "Bahrain (+973)"
        },
        "BI": {
            "value": "257",
            "textZh": "布隆迪(+257)",
            "textEn": "Burundi (+257)"
        },
        "BJ": {
            "value": "229",
            "textZh": "贝宁(+229)",
            "textEn": "Benin (+229)"
        },
        "BW": {
            "value": "267",
            "textZh": "博茨瓦纳(+267)",
            "textEn": "Botswana (+267)"
        },
        "WS": {
            "value": "685",
            "textZh": "西萨摩亚(+685)",
            "textEn": "Samoa Western (+685)"
        },
        "BQ": {
            "value": "599"
        },
        "BR": {
            "value": "55",
            "textZh": "巴西(+55)",
            "textEn": "Brazil (+55)"
        },
        "BY": {
            "value": "375",
            "textZh": "白俄罗斯(+375)",
            "textEn": "Belarus (+375)"
        },
        "BZ": {
            "value": "501",
            "textZh": "伯利兹(+501)",
            "textEn": "Belize (+501)"
        },
        "RU": {
            "value": "7"
        },
        "RS": {
            "value": "381",
            "textZh": "南斯拉夫(+381)",
            "textEn": "Yugoslavia (+381)"
        },
        "RE": {
            "value": "262"
        },
        "TM": {
            "value": "993",
            "textZh": "土库曼斯坦(+993)",
            "textEn": "Turkmenistan (+993)"
        },
        "TJ": {
            "value": "992",
            "textZh": "塔吉克斯坦(+992)",
            "textEn": "Tajikstan (+992)"
        },
        "RO": {
            "value": "40",
            "textZh": "罗马尼亚(+40)",
            "textEn": "Romania (+40)"
        },
        "GT": {
            "value": "502",
            "textZh": "危地马拉(+502)",
            "textEn": "Guatemala (+502)"
        },
        "GR": {
            "value": "30",
            "textZh": "希腊(+30)",
            "textEn": "Greece (+30)"
        },
        "JP": {
            "value": "81",
            "textZh": "日本(+81)",
            "textEn": "Japan (+81)"
        },
        "GY": {
            "value": "592",
            "textZh": "圭亚那 (+592)",
            "textEn": "Guyana (+592)"
        },
        "GF": {
            "value": "594",
            "textZh": "法属圭亚那(+594)",
            "textEn": "French Guiana (+594)"
        },
        "GE": {
            "value": "995",
            "textZh": "格鲁吉亚(+995)",
            "textEn": "Georgia (+995)"
        },
        "GB": {
            "value": "44",
            "textZh": "英国(+44)",
            "textEn": "United Kingdom (+44)"
        },
        "GA": {
            "value": "241",
            "textZh": "加蓬(+241)",
            "textEn": "Gabon (+241)"
        },
        "SV": {
            "value": "503",
            "textZh": "萨尔瓦多(+503)",
            "textEn": "EI Salvador (+503)"
        },
        "GN": {
            "value": "224",
            "textZh": "几内亚 (+224)",
            "textEn": "Guinea (+224)"
        },
        "GM": {
            "value": "220",
            "textZh": "冈比亚(+220)",
            "textEn": "Gambia (+220)"
        },
        "GI": {
            "value": "350",
            "textZh": "直布罗陀(英)(+350)",
            "textEn": "Gibraltar (+350)"
        },
        "GH": {
            "value": "233",
            "textZh": "乌兹别克斯坦(+233)",
            "textEn": "Uzbekistan (+233)"
        },
        "OM": {
            "value": "968",
            "textZh": "阿曼(+968)",
            "textEn": "Oman (+968)"
        },
        "TN": {
            "value": "216",
            "textZh": "突尼斯(+216)",
            "textEn": "Tunisia (+216)"
        },
        "JO": {
            "value": "962",
            "textZh": "约旦(+962)",
            "textEn": "Jordan (+962)"
        },
        "HT": {
            "value": "509",
            "textZh": "海地(+509)",
            "textEn": "Haiti (+509)"
        },
        "HU": {
            "value": "36",
            "textZh": "匈牙利(+36)",
            "textEn": "Hungary (+36)"
        },
        "HN": {
            "value": "504",
            "textZh": "洪都拉斯(+504)",
            "textEn": "Honduras (+504)"
        },
        "VE": {
            "value": "58",
            "textZh": "委内瑞拉(+58)",
            "textEn": "Venezuela (+58)"
        },
        "PT": {
            "value": "351",
            "textZh": "葡萄牙(+351)",
            "textEn": "Portugal (+351)"
        },
        "SJ": {
            "value": "47"
        },
        "PY": {
            "value": "595",
            "textZh": "巴拉圭(+595)",
            "textEn": "Paraguay (+595)"
        },
        "IQ": {
            "value": "964",
            "textZh": "伊拉克(+964)",
            "textEn": "Iraq (+964)"
        },
        "PA": {
            "value": "507",
            "textZh": "巴拿马(+507)",
            "textEn": "Panama (+507)"
        },
        "PF": {
            "value": "689",
            "textZh": "法属波利尼西亚(+689)",
            "textEn": "French Polynesia (+689)"
        },
        "PG": {
            "value": "675",
            "textZh": "巴布亚新几内亚(+675)",
            "textEn": "Papua New Cuinea (+675)"
        },
        "PE": {
            "value": "51",
            "textZh": "秘鲁(+51)",
            "textEn": "Peru (+51)"
        },
        "PK": {
            "value": "92",
            "textZh": "巴基斯坦(+92)",
            "textEn": "Pakistan (+92)"
        },
        "PH": {
            "value": "63",
            "textZh": "菲律宾(+63)",
            "textEn": "Philippines (+63)"
        },
        "PL": {
            "value": "48",
            "textZh": "波兰(+48)",
            "textEn": "Poland (+48)"
        },
        "ZM": {
            "value": "260",
            "textZh": "赞比亚(+260)",
            "textEn": "Zambia (+260)"
        },
        "EH": {
            "value": "212"
        },
        "EE": {
            "value": "372",
            "textZh": "爱沙尼亚(+372)",
            "textEn": "Estonia (+372)"
        },
        "EG": {
            "value": "20",
            "textZh": "埃及(+20)",
            "textEn": "Egypt (+20)"
        },
        "ZA": {
            "value": "27",
            "textZh": "南非(+27)",
            "textEn": "South Africa (+27)"
        },
        "EC": {
            "value": "593",
            "textZh": "厄瓜多尔(+593)",
            "textEn": "Ecuador (+593)"
        },
        "IT": {
            "value": "39",
            "textZh": "意大利(+39)",
            "textEn": "Italy (+39)"
        },
        "VN": {
            "value": "84",
            "textZh": "越南(+84)",
            "textEn": "Vietnam (+84)"
        },
        "SB": {
            "value": "677",
            "textZh": "所罗门群岛(+677)",
            "textEn": "Solomon Is (+677)"
        },
        "ET": {
            "value": "251",
            "textZh": "埃塞俄比亚(+251)",
            "textEn": "Ethiopia (+251)"
        },
        "SO": {
            "value": "252",
            "textZh": "索马里(+252)",
            "textEn": "Somali (+252)"
        },
        "ZW": {
            "value": "263",
            "textZh": "津巴布韦(+263)",
            "textEn": "Zimbabwe (+263)"
        },
        "SA": {
            "value": "966",
            "textZh": "沙特阿拉伯(+966)",
            "textEn": "Saudi Arabia (+966)"
        },
        "ES": {
            "value": "34",
            "textZh": "西班牙(+34)",
            "textEn": "Spain (+34)"
        },
        "MD": {
            "value": "373",
            "textZh": "摩尔多瓦共和国(+373)",
            "textEn": "Republic of Moldova (+373)"
        },
        "MG": {
            "value": "261",
            "textZh": "马达加斯加(+261)",
            "textEn": "Madagascar (+261)"
        },
        "MA": {
            "value": "212",
            "textEn": "Morocco (+212)"
        },
        "MC": {
            "value": "377",
            "textZh": "摩纳哥(+377)",
            "textEn": "Monaco (+377)"
        },
        "MM": {
            "value": "95",
            "textZh": "缅甸(+95)",
            "textEn": "Burma (+95)"
        },
        "ML": {
            "value": "223",
            "textZh": "马里(+223)",
            "textEn": "Mali (+223)"
        },
        "MO": {
            "value": "853",
            "textZh": "澳门(+853)",
            "textEn": "Macao (+853)"
        },
        "MN": {
            "value": "976",
            "textZh": "蒙古(+976)",
            "textEn": "Mongolia (+976)"
        },
        "MU": {
            "value": "230",
            "textZh": "毛里求斯(+230)",
            "textEn": "Mauritius (+230)"
        },
        "MT": {
            "value": "356",
            "textZh": "马耳他(+356)",
            "textEn": "Malta (+356)"
        },
        "MW": {
            "value": "265",
            "textZh": "马拉维(+265)",
            "textEn": "Malawi (+265)"
        },
        "MV": {
            "value": "960",
            "textZh": "马尔代夫(+960)",
            "textEn": "Maldives (+960)"
        },
        "UG": {
            "value": "256",
            "textZh": "乌干达(+256)",
            "textEn": "Uganda (+256)"
        },
        "TZ": {
            "value": "255",
            "textZh": "坦桑尼亚(+255)",
            "textEn": "Tanzania (+255)"
        },
        "MY": {
            "value": "60",
            "textZh": "马来西亚(+60)",
            "textEn": "Malaysia (+60)"
        },
        "MX": {
            "value": "52",
            "textZh": "墨西哥(+52)",
            "textEn": "Mexico (+52)"
        },
        "IL": {
            "value": "972",
            "textZh": "以色列(+972)",
            "textEn": "Israel (+972)"
        },
        "FR": {
            "value": "33",
            "textZh": "法国(+33)",
            "textEn": "France (+33)"
        },
        "FI": {
            "value": "358",
            "textZh": "芬兰(+358)",
            "textEn": "Finland (+358)"
        },
        "FJ": {
            "value": "679",
            "textZh": "斐济(+679)",
            "textEn": "Fiji (+679)"
        },
        "NI": {
            "value": "505",
            "textZh": "尼加拉瓜(+505)",
            "textEn": "Nicaragua (+505)"
        },
        "NL": {
            "value": "31",
            "textZh": "荷兰(+31)",
            "textEn": "Netherlands (+31)"
        },
        "NO": {
            "value": "47",
            "textEn": "Norway (+47)"
        },
        "NA": {
            "value": "264",
            "textZh": "纳米尼亚(+264)",
            "textEn": "Namibia (+264)"
        },
        "NE": {
            "value": "227",
            "textZh": "尼日尔(+227)",
            "textEn": "Niger (+227)"
        },
        "NG": {
            "value": "234",
            "textZh": "尼日利亚(+234)",
            "textEn": "Nigeria (+234)"
        },
        "NZ": {
            "value": "64",
            "textZh": "新西兰(+64)",
            "textEn": "New Zealand (+64)"
        },
        "NP": {
            "value": "977",
            "textZh": "尼泊尔(+977)",
            "textEn": "Nepal (+977)"
        },
        "NR": {
            "value": "674",
            "textZh": "瑙鲁(+674)",
            "textEn": "Nauru (+674)"
        },
        "CK": {
            "value": "682",
            "textZh": "科克群岛(新)(+682)",
            "textEn": "Cook Is. (+682)"
        },
        "CI": {
            "value": "225",
            "textZh": "科特迪瓦(+225)",
            "textEn": "Ivory Coast (+225)"
        },
        "CH": {
            "value": "41",
            "textZh": "瑞士(+41)",
            "textEn": "Switzerland (+41)"
        },
        "CO": {
            "value": "57",
            "textZh": "哥伦比亚(+57)",
            "textEn": "Colombia (+57)"
        },
        "CM": {
            "value": "237",
            "textZh": "喀麦隆(+237)",
            "textEn": "Cameroon (+237)"
        },
        "CL": {
            "value": "56",
            "textZh": "智利(+56)",
            "textEn": "Chile (+56)"
        },
        "CC": {
            "value": "61"
        },
        "CA": {
            "value": "1"
        },
        "CG": {
            "value": "242",
            "textZh": "刚果(+242)",
            "textEn": "Congo (+242)"
        },
        "CF": {
            "value": "236",
            "textZh": "中非(+236)",
            "textEn": "Central African Republic (+236)"
        },
        "CD": {
            "value": "243",
            "textZh": "刚果(+243)",
            "textEn": "Zaire (+243)"
        },
        "CZ": {
            "value": "420",
            "textZh": "捷克共和国(+420)",
            "textEn": "Czech Republic (+420)"
        },
        "CY": {
            "value": "357",
            "textZh": "塞浦路斯(+357)",
            "textEn": "Cyprus (+357)"
        },
        "CX": {
            "value": "61"
        },
        "CR": {
            "value": "506",
            "textZh": "哥斯达黎加(+506)",
            "textEn": "Costa Rica (+506)"
        },
        "CW": {
            "value": "599"
        },
        "CU": {
            "value": "53",
            "textZh": "古巴(+53)",
            "textEn": "Cuba (+53)"
        },
        "SZ": {
            "value": "268",
            "textZh": "斯威士兰(+268)",
            "textEn": "Swaziland (+268)"
        },
        "SY": {
            "value": "963",
            "textZh": "叙利亚(+963)",
            "textEn": "Syria (+963)"
        },
        "SX": {
            "value": "599",
            "textEn": "Netheriands Antilles (+599)"
        },
        "KE": {
            "value": "254",
            "textZh": "肯尼亚(+254)",
            "textEn": "Kenya (+254)"
        },
        "SR": {
            "value": "597",
            "textZh": "苏里南(+597)",
            "textEn": "Suriname (+597)"
        },
        "KH": {
            "value": "855",
            "textZh": "柬埔寨(+855)",
            "textEn": "Kampuchea (Cambodia) (+855)"
        },
        "ST": {
            "value": "239",
            "textZh": "圣多美和普林西比(+239)",
            "textEn": "Sao Tome and Principe (+239)"
        },
        "SK": {
            "value": "421",
            "textZh": "斯洛伐克(+421)",
            "textEn": "Slovakia (+421)"
        },
        "KR": {
            "value": "82",
            "textZh": "韩国(+82)",
            "textEn": "Korea (+82)"
        },
        "SI": {
            "value": "386",
            "textZh": "斯洛文尼亚(+386)",
            "textEn": "Slovenia (+386)"
        },
        "KP": {
            "value": "850",
            "textZh": "朝鲜(+850)",
            "textEn": "North Korea (+850)"
        },
        "KW": {
            "value": "965",
            "textZh": "科威特(+965)",
            "textEn": "Kuwait (+965)"
        },
        "SN": {
            "value": "221",
            "textZh": "塞内加尔(+221)",
            "textEn": "Senegal (+221)"
        },
        "SM": {
            "value": "378",
            "textZh": "圣马力诺 (+378)",
            "textEn": "San Marino (+378)"
        },
        "SL": {
            "value": "232",
            "textZh": "塞拉利(+232)",
            "textEn": "Sierra Leone (+232)"
        },
        "SC": {
            "value": "248",
            "textZh": "塞舌尔(+248)",
            "textEn": "Seychelles (+248)"
        },
        "KZ": {
            "value": "7",
            "textEn": "Russia (+7)"
        },
        "SG": {
            "value": "65",
            "textZh": "新加坡(+65)",
            "textEn": "Singapore (+65)"
        },
        "SE": {
            "value": "46",
            "textZh": "瑞典(+46)",
            "textEn": "Sweden (+46)"
        },
        "SD": {
            "value": "249",
            "textZh": "苏丹(+249)",
            "textEn": "Sudan (+249)"
        },
        "DJ": {
            "value": "253",
            "textZh": "吉布提(+253)",
            "textEn": "Djibouti (+253)"
        },
        "DK": {
            "value": "45",
            "textZh": "丹麦(+45)",
            "textEn": "Denmark (+45)"
        },
        "DE": {
            "value": "49",
            "textZh": "德国(+49)",
            "textEn": "Germany (+49)"
        },
        "YE": {
            "value": "967",
            "textZh": "也门(+967)",
            "textEn": "Yemen (+967)"
        },
        "DZ": {
            "value": "213",
            "textZh": "阿尔及尼亚(+213)",
            "textEn": "Algeria (+213)"
        },
        "US": {
            "value": "1"
        },
        "UY": {
            "value": "598",
            "textZh": "乌拉圭(+598)",
            "textEn": "Uruguay (+598)"
        },
        "YT": {
            "value": "262",
            "textEn": "Reunion (+262)"
        },
        "UM": {
            "value": "1",
            "textEn": "America/Canada (+1)"
        },
        "LA": {
            "value": "856",
            "textZh": "老挝(+856)",
            "textEn": "Laos (+856)"
        },
        "TR": {
            "value": "90",
            "textZh": "土耳其 (+90)",
            "textEn": "Turkey (+90)"
        },
        "LK": {
            "value": "94",
            "textZh": "斯里兰卡(+94)",
            "textEn": "Sri Lanka (+94)"
        },
        "LI": {
            "value": "423",
            "textZh": "列支敦士登(+423)",
            "textEn": "Liechtenstein (+423)"
        },
        "LV": {
            "value": "371",
            "textZh": "拉脱维亚(+371)",
            "textEn": "Latvia (+371)"
        },
        "TO": {
            "value": "676",
            "textZh": "汤加(+676)",
            "textEn": "Tonga (+676)"
        },
        "LT": {
            "value": "370",
            "textZh": "立陶宛(+370)",
            "textEn": "Lithuania (+370)"
        },
        "LU": {
            "value": "352",
            "textZh": "卢森堡(+352)",
            "textEn": "Luxembourg (+352)"
        },
        "LR": {
            "value": "231",
            "textZh": "利比里亚(+231)",
            "textEn": "Liberia (+231)"
        },
        "LS": {
            "value": "266",
            "textZh": "黎巴嫩(+266)",
            "textEn": "Lesotho (+266)"
        },
        "TH": {
            "value": "66",
            "textZh": "泰国(+66)",
            "textEn": "Thailand (+66)"
        },
        "TG": {
            "value": "228",
            "textZh": "多哥(+228)",
            "textEn": "Togo (+228)"
        },
        "TD": {
            "value": "235",
            "textZh": "乍得(+235)",
            "textEn": "Chad (+235)"
        },
        "LY": {
            "value": "218",
            "textZh": "利比亚(+218)",
            "textEn": "Libya (+218)"
        },
        "AE": {
            "value": "971",
            "textZh": "阿拉伯联合酋长国(+971)",
            "textEn": "United Arab Emirates (+971)"
        },
        "AD": {
            "value": "376",
            "textZh": "安道尔(+376)",
            "textEn": "Andorra (+376)"
        },
        "AF": {
            "value": "93",
            "textZh": "阿富汗(+93)",
            "textEn": "Afghanistan (+93)"
        },
        "IS": {
            "value": "354",
            "textZh": "冰岛(+354)",
            "textEn": "Iceland (+354)"
        },
        "IR": {
            "value": "98",
            "textZh": "伊朗(+98)",
            "textEn": "Iran (+98)"
        },
        "AM": {
            "value": "374",
            "textZh": "亚美尼亚(+374)",
            "textEn": "Armenia (+374)"
        },
        "AL": {
            "value": "355",
            "textZh": "阿尔巴尼亚(+355)",
            "textEn": "Albania (+355)"
        },
        "AO": {
            "value": "244",
            "textZh": "安哥拉(+244)",
            "textEn": "Angola (+244)"
        },
        "AR": {
            "value": "54",
            "textZh": "阿根廷(+54)",
            "textEn": "Argentina (+54)"
        },
        "AU": {
            "value": "61",
            "textEn": "Australia (+61)"
        },
        "AT": {
            "value": "43",
            "textZh": "奥地利(+43)",
            "textEn": "Austria (+43)"
        },
        "IN": {
            "value": "91",
            "textZh": "印度(+91)",
            "textEn": "India (+91)"
        },
        "AZ": {
            "value": "994",
            "textZh": "阿塞拜疆(+994)",
            "textEn": "Azerbaijan (+994)"
        },
        "IE": {
            "value": "353",
            "textZh": "爱尔兰(+353)",
            "textEn": "Ireland (+353)"
        },
        "ID": {
            "value": "62",
            "textZh": "印度尼西亚(+62)",
            "textEn": "Indonesia (+62)"
        },
        "UA": {
            "value": "380",
            "textZh": "乌克兰(+380)",
            "textEn": "Ukraine (+380)"
        },
        "QA": {
            "value": "974",
            "textZh": "卡塔尔(+974)",
            "textEn": "Qatar (+974)"
        },
        "MZ": {
            "value": "258",
            "textZh": "莫桑比克(+258)",
            "textEn": "Mozambique (+258)"
        }
    }
export {
    phoneListZh, phoneListEn, countryNumbers, transtions
};
