import {Pipe, PipeTransform} from '@angular/core';
import {Item} from 'aigens-ng-core';
import {SystemConfigService} from '../../core/services/systemConfig.service';
import {DecimalPipe} from '@angular/common';
import {OrderService} from '../../core/services/order.service';

@Pipe({
    name: 'itemPrice'
})
export class ItemPricePipe implements PipeTransform {
    constructor(public systemConfigService: SystemConfigService, public decimalPipe: DecimalPipe, public orderService: OrderService) {
    }

    transform(item: Item, quantity: number = 0): string {
        let p = this.systemConfigService.itemPricePrecision;
        let setting = this.orderService.getCurrentStoreSetting();
        if (p >= 0) {
            let dc = '1.' + p + '-' + p;
            if (setting.showDefaultPrice) {
                if (quantity > 0) {
                    return this.decimalPipe.transform((item.price0 ? item.price0 : item.price) * quantity, dc);
                } else {
                    return this.decimalPipe.transform((item.price0 ? item.price0 : item.price) * 1, dc);
                }
            } else {
                if (quantity > 0) {
                    return this.decimalPipe.transform(item.price * quantity, dc);
                } else {
                    return this.decimalPipe.transform(item.price * 1, dc);
                }
            }
        } else {
            let dc = '1.0-2';
            if (setting.showDefaultPrice) {
                if (quantity > 0) {
                    return this.decimalPipe.transform((item.price0 ? item.price0 : item.price) * quantity, dc);
                } else {
                    return this.decimalPipe.transform((item.price0 ? item.price0 : item.price) * 1, dc);
                }
            } else {
                if (quantity > 0) {
                    return this.decimalPipe.transform(item.price * quantity, dc);
                } else {
                    return this.decimalPipe.transform(item.price * 1, dc);
                }
            }
        }
    }
}
