import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderService} from '../../../core/services/order.service';

@Component({
    selector: 'custom-back-button',
    templateUrl: './custom-back-button.component.html',
    styleUrls: ['./custom-back-button.component.scss'],
})
export class CustomBackButtonComponent {

    @Output()
    backButtonClick = new EventEmitter<any>();

    @Input()
    forceHideText = false;

    @Input()
    darkText = false;

    @Input()
    overwriteTranslateKey: string;

    hideBackText: boolean;

    constructor(private orderService: OrderService) {
        this.hideBackText = this.orderService.getCurrentStoreSetting() ? this.orderService.getCurrentStoreSetting()['hideBackLbl'] : false;
    }

    onBackButtonClick() {
        this.backButtonClick.emit();
    }
}
