import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appDefaultImg]',
})

export class DefaultImgDirective {
    @Input() appDefaultImg: string;

    constructor(public el: ElementRef) {
    }

    @HostListener('error')
    updateUrl() {
        if (this.el && this.el.nativeElement && this.appDefaultImg) {
            this.el.nativeElement.src = this.appDefaultImg;
        }
    }

}
