import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { Brand } from 'aigens-ng-core';
import { Component, Injector, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MobileBaseItemPage } from 'src/app/core/base/mobile-base-item-page';
/**
 * Generated class for the Passcode page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-pickup-time',
    templateUrl: 'pickupTime.html',
    styleUrls: ['pickupTime.scss']

})
export class PickupTimeDialog extends MobileBasePage {

    // passcode: string;
    // wrong: boolean = false;
    // lock:boolean = false;
    // regExp:string;
    // brand:Brand;
    @ViewChild('input') myInput;

    // settings:any;

    // spotRegex:string;
    // spotDesc:string;
    headCount: number;
    nowUnix: any;
    months: any;
    days: any = [];
    hours: any = [];
    mins: any = [];
    min: any;
    max: any;
    myDate: any;
    now = new Date();
    nowMax: any;
    nowMin: any;
    store: any;
    mapOfPeriod: any = {};
    currentAvailablePeriod: any = [];
    categoryPeriodMap: any = {};
    listOfKey: any = [];
    periodLimStart: any = 0;
    periodLimEnd: any = 0;
    resDate: Date;


    todayOpt: any;
    tmrOtp: any;
    dayAfterTmrOpt: any;
    chooseOtp: any;

    todayOptDisplay: any;
    tmrOtpDisplay: any;
    dayAfterTmrOptDisplay: any;

    dateDisplayMap: any = {};

    isTodayPicker = true;

    monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    constructor(private injector: Injector) {
        super(injector);
        this.store = this.orderService.store;
        this.initPeriod();
    }

    ionViewDidLoad() {
        console.log('ionViewDidLoad PasscodeDlg');
    }

    dismissClicked() {
        console.log('dismiss clicked');
        this.headCount = null;
        this.modalController.dismiss();
    }
    confirmClicked(res: any = null) {
        console.log('dismiss clicked', res);
        if (res === 'now') {
            this.modalController.dismiss(null);
            return;
        }
        if (this.resDate && this.myDate) {
            let cats = this.menuManager.getAvailableCategories(this.store, this.resDate.getTime(), this.orderManager.mode === 'takeaway');
            console.log('cats', cats);
            if (cats && cats.length > 0) {
                let output = {
                    time: this.resDate.getTime(),
                    offset: this.resDate.getTimezoneOffset()
                };
                this.modalController.dismiss(output);
            } else {
                this.showAlert('', 'no available items');
            }
        } else {
            // this.viewCtrl.dismiss(null);
            this.showAlert('', 'please choose a valid date');
        }

    }

    // submit() {
    //     console.log("date time", this.myDate);
    //     console.log("check sumbit");
    //     if(this.headCount > 0 && this.headCount < 20)
    //     {
    //         this.orderService.seats = this.headCount;
    //         this.orderService.editHeadCount().subscribe((result) => {
    //             console.log("edit headcount result", result);
    //             this.viewCtrl.dismiss(this.headCount);
    //         }, err => {
    //             this.showAlert(err.status, err["_body"]);
    //         })
    //     }else
    //     {
    //         this.showAlert("", "Invalid head count input");
    //     }

    // }

    dismissKeyboardHandling() {

        this.myInput.setFocus();


    }

    pickDate(date) {

        this.myDate = null;
        if (date === '0') {
            this.isTodayPicker = true;
        } else {
            this.isTodayPicker = false;
        }

        switch (date) {
            case '0': {
                this.chooseOtp = this.todayOpt;
                break;
            }
            case '1': {
                this.chooseOtp = this.tmrOtp;
                break;
            }
            case '2': {
                this.chooseOtp = this.dayAfterTmrOpt;
                break;
            }
        }

    }

    dateSetting(end) {

        let current = new Date();
        let b = new Date(new Date().setMinutes(new Date().getMinutes() + 45));
        // let a = new Date(new Date(end).setDate(new Date(end).getDate()+3));

        let a = new Date(new Date().setDate(new Date().getDate() + 3));
        let d1 = new Date(new Date().setDate(new Date().getDate() + 1));
        let d2 = new Date(new Date().setDate(new Date().getDate() + 2));
        let max = new Date(a);
        let min = new Date(b);


        this.todayOpt = b;
        this.tmrOtp = d1;
        this.dayAfterTmrOpt = d2;

        this.todayOptDisplay = new Date(this.todayOpt.getTime() - (this.todayOpt.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.tmrOtpDisplay = new Date(this.tmrOtp.getTime() - (this.tmrOtp.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.dayAfterTmrOptDisplay = new Date(this.dayAfterTmrOpt.getTime() - (this.dayAfterTmrOpt.getTimezoneOffset() * 60000)).toISOString().split('T')[0];

        // console.log('todayOptDisplay', this.todayOptDisplay, this.monthNames[b.getMonth()], b.getFullYear(),b.getDate());
        this.dateDisplayMap['0month'] = this.monthNames[this.todayOpt.getMonth()] + ' / ' + this.todayOpt.getFullYear();
        this.dateDisplayMap['0day'] = this.todayOpt.getDate() < 10 ? '0' + this.todayOpt.getDate() : this.todayOpt.getDate();
        this.dateDisplayMap['1month'] = this.monthNames[this.tmrOtp.getMonth()] + ' / ' + this.tmrOtp.getFullYear();
        this.dateDisplayMap['1day'] = this.tmrOtp.getDate() < 10 ? '0' + this.tmrOtp.getDate() : this.tmrOtp.getDate();
        this.dateDisplayMap['2month'] = this.monthNames[this.dayAfterTmrOpt.getMonth()] + ' / ' + this.dayAfterTmrOpt.getFullYear();
        this.dateDisplayMap['2day'] = this.dayAfterTmrOpt.getDate() < 10 ? '0' + this.dayAfterTmrOpt.getDate() : this.dayAfterTmrOpt.getDate();

        this.nowMax = new Date(max.getTime() - (max.getTimezoneOffset() * 60000)).toISOString();
        this.nowMin = new Date(min.getTime() - (min.getTimezoneOffset() * 60000)).toISOString();
        console.log('nowMax', this.nowMax);
        console.log('nowMin', this.nowMin);

        this.pickDate('0');


    }

    updateMyDate(ev) {
        console.log('ev', ev);
        let date = new Date();
        // date.setDate(ev.day);
        date = this.chooseOtp;
        date.setHours(ev.hour);
        date.setMinutes(ev.minute);

        // date.setMonth(ev.month - 1);

        console.log('date', date, date.getTime(), new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString());
        this.resDate = date;
    }
    initPeriod() {
        // inti period

        if (this.store.menu.periods) {
            let periods = this.store.menu.periods;
            for (let period of periods) {

                if (this.isAvailablePeriod(this.store, period, new Date())) {
                    this.currentAvailablePeriod.push(period.code);
                }

                if (!period.start || !period.end) {
                    continue;
                } else {
                    let startTime = period.startTime;
                    let endTime = period.endTime;
                    let time = new Date().getTime();

                    let start = moment(startTime, 'HH:mm');
                    let end = moment(endTime, 'HH:mm');

                    console.log('start', start);
                    console.log('end', end);

                    let now = moment(time);

                    start.year(now.year());
                    start.month(now.month());
                    start.dayOfYear(now.dayOfYear());

                    end.year(now.year());
                    end.month(now.month());
                    end.dayOfYear(now.dayOfYear());

                    if (end < start) {
                        console.log('stepped over time to next day!!!');
                        if (now > end) {
                            end = end.add(24, 'hours');
                        } else {
                            start = start.add(-24, 'hours');
                        }
                    }

                    console.log('start', start.valueOf());
                    console.log('end', end.valueOf());
                    console.log('now', now.valueOf());

                    if (now > end) {
                        console.log('wont show', period.code);
                    } else {
                        if (this.periodLimStart === 0 || this.periodLimStart.valueOf() > start.valueOf()) {
                            // this.periodLimStart = start.valueOf();
                            this.periodLimStart = start;
                        }

                        if (this.periodLimEnd === 0 || this.periodLimEnd.valueOf() < end.valueOf()) {
                            // this.periodLimEnd = end.valueOf();
                            this.periodLimEnd = end;
                        }

                        this.mapOfPeriod[period.code + 'start'] = start;
                        this.mapOfPeriod[period.code + 'end'] = end;
                        let cats = null;
                        // if (this.currentAvailablePeriod.indexOf(period.code) >= 0) {
                        //     cats = this.menuManger.getAvailableCategories(this.store, new Date().getTime(), this.orderManager.mode === 'takeaway');
                        // } else {
                        //     cats = this.menuManger.getAvailableCategories(this.store, start.valueOf(), this.orderManager.mode === 'takeaway');
                        // }

                        if (cats) {
                            this.categoryPeriodMap[period.code] = cats;
                        }

                    }
                }
            }
            console.log(this.periodLimStart);
            console.log(this.periodLimEnd);
            this.listOfKey = Object.keys(this.mapOfPeriod);
            console.log('this.categoryPeriodMap[period.code]', this.categoryPeriodMap);
            this.dateSetting(this.periodLimEnd.valueOf());
        }
    }
}
