import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isEmailValid, isPhoneValid } from '../../utilities/checkFormatTool';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { getCountryCallingCode, isPhoneIncludingCode, findCountryByCodes } from '../../../shared/utilities/checkFormatTool';
import { TermsConditionsPage } from 'src/app/dialogs/terms-conditions/terms-conditions';
import * as phoneData from '../../../../assets/phoneData';
import { StoreService } from 'src/app/core/services/store.service';


@Component({
    selector: 'app-guest-info',
    templateUrl: './guest-info.component.html',
    styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent extends MobileBasePage implements OnInit {

    email: string;
    @Input()
    needEmail = true;
    @Input()
    showEmail = true;

    countryCallingCode: string;
    phone: string;

    firstName: string;
    name: string;
    spot: string;

    @Input()
    needPhone = true;
    @Input()
    showPhone = true;

    needPassword = false;

    @Input() set inputName(value: string) {
        if (!this.isNameDirty) {
            this.name = value;
            this.orderService.name = value;
        }
    }

    @Input()
    allowPhoneNotify = false;

    @Output()
    emailChange = new EventEmitter<string>();

    @Output()
    phoneChange = new EventEmitter<string>();

    @Output()
    nameChange = new EventEmitter<string>();

    @Output() countryCodeCb = new EventEmitter();

    isNameDirty = false;

    emailClicked = false;
    phoneClicked = false;

    guestRegistration = false;
    countryCodes=[];
    // countrycode Data
    countryNumbers = phoneData.countryNumbers;
    phoneListEn = phoneData.phoneListEn;
    transtions = phoneData.transtions;

    @ViewChild('paymentpw2') pw2Input;

    constructor(private injector: Injector,  public storeService: StoreService) {
        super(injector);
        this.phone = this.orderService.phone;
        this.email = this.orderService.email;
        this.name = this.orderService.name;
        this.spot = this.orderService.spot;
        console.log(this.systemConfigService);

        this.guestRegistration = this.systemConfigService.guestRegistration;
        console.log('guestRegistration', this.guestRegistration);
    }

    ngOnInit() {

        this.getAddressField();

        // court mode init some config (由于当前组件部分 ui input 系 control by setting in store-level)
        this.orderService.isCourt && this.initCourtConfig();

        if (!this.countryCallingCode) {
            this.countryCallingCode = getCountryCallingCode(this.orderService.store.country);
            this.orderService.countryCode = this.countryCallingCode;
        }
        // console.log('phone required?',this.needPhone, this.settings.phoneRequired);
        console.log('member mes', this.memberService.getMember());
        let member = this.memberService.getMember();
        if (member) {

            if (member['method'] === 'device') {
                this.needPassword = true;
            }
            if (member.email) {
                this.email = member.email;
            }
            if (member.phone) {
                let phoneNoAddSymbol;
                if (member.phone[0] === '+') {
                    phoneNoAddSymbol = member.phone.slice(1, member.phone.length);
                } else {
                    phoneNoAddSymbol = member.phone;
                }

                // 根据 member.countryCode 查找 country 'HK’之类
                let countryCode = member['countryCode'];
                if (countryCode) {
                    this.countryCallingCode = countryCode[0] === '+' ? countryCode.slice(1, countryCode.length) : countryCode;
                }
                if (isPhoneValid(phoneNoAddSymbol, findCountryByCodes(this.countryCallingCode))) {
                    // todo pass country the phone belong to , not pass the memberService.country

                    let { noCodePhone } = isPhoneIncludingCode(phoneNoAddSymbol, findCountryByCodes(this.countryCallingCode));
                    if (noCodePhone) {
                        this.phone = noCodePhone;
                        this.orderService.phone = this.phone;
                    } else {
                        // because of the country not exact
                        // the noCodePhone
                        this.phone = phoneNoAddSymbol;
                        this.orderService.phone = this.phone;
                    }
                }
            }
        }

        if(!this.allowPhoneNotify) {
            this.orderService.phoneNotify = false;
        }

    }

    initCourtConfig() {
        if (this.orderService.isCourt) {
            this.showPhone = !this.systemConfigService.hideCourtGuestInfoPhoneInput;
            this.showEmail = !this.systemConfigService.hideCourtGuestInfoEmailInput;
        }
    }

    getAddressField() {
        let countryCodes = this.storeService.storeCountryCodesArr;
        if (countryCodes && countryCodes.length > 0) {
            this.countryCodes = countryCodes;
            return;
        }
        if (this.orderManager && this.orderManager.store && this.orderManager.store.brand && this.orderManager.store.brand.id) {
          this.loading(true);
          this.storeService.getStoreRegionsByBandId(this.orderManager.store.brand.id).subscribe((res) => {
            this.loading(false);
            if (res) {
              // save the regions data
              console.log('getAddressField', res);
              if (res.form) {
                let countryCodes = res.form.countryCodes;
                countryCodes.map((item, index)=>{
                    countryCodes[index] ? countryCodes[index].code =  item.code.slice(1) : null;
                })
                  console.log(countryCodes)
                  this.countryCodes = countryCodes
              } else {
                 let  defaultCodes = [
                    { country: 'ID', code: "62" },
                    { country: "HK", code: '852' },
                    { country: "CN", code: '86' }
                ]
                this.countryCodes = defaultCodes
                // this.showry_form ');
             
                // this.showAlert('', 'server no return the country_form ');
              }
              this.storeService.setStoreCountryCodesArr(this.countryCodes);
            }
          }, err => {
            this.loading(false);
            this.showError(err);
            // todo  handle this condition
          });
        } else {
            let defaultCodes = [
                { country: 'ID', code: "62" },
                { country: 'SG', code: '65' },
                { country: "HK", code: '852' },
                { country: "CN", code: '86' }
            ]
            this.countryCodes = defaultCodes;
            !this.orderService.countryCode && (this.orderService.countryCode = defaultCodes[0].code);
            this.storeService.setStoreCountryCodesArr(this.countryCodes);
        }
    
      }

    emailChangeHandle() {
        console.log('on email change:', this.email);
        if ((this.needEmail || this.email) && !isEmailValid(this.email)) {
            this.emailClicked = true;
            this.orderService.email = null;
            if (this.needEmail && !this.email)  return
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            return;
        } else if (!this.needEmail && !this.email){
            this.orderService.email = null;
        } else if (isEmailValid(this.email)) {
            this.configService.putPreference('aigens-email', this.email);
            this.orderService.email = this.email;
        }
        this.emailChange.emit();
    }

    getCountryCallingCode() {
        return getCountryCallingCode();
    }

    phoneChangeHandle() {
        console.log('on componenet phone change:', this.phone);
        if ((this.needPhone || this.phone) && !isPhoneValid(this.phone, findCountryByCodes(this.countryCallingCode))) {
            this.phoneClicked = true;
            this.orderService.phone = null;
            if (this.needPhone && !this.phone) return;
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            return;
        } else if (!this.needPhone && !this.phone){
            this.orderService.phone = null;
        }else if (isPhoneValid(this.phone, findCountryByCodes(this.countryCallingCode))) {
            this.configService.putPreference('aigens-phone', this.phone);
            this.orderService.phone = this.phone;
        }
        this.phoneChange.emit();
    }
    changeCountryCode() {
        let el = document.getElementsByClassName('alert-radio-label');

        setTimeout(() => {
            // console.log("el[index]:",el);
            this.countryCodes.map((countryCode, index)=>{
                let imageUrl = '../../../../../assets/images/flags/' + countryCode.country + '/flat/64.png';
                if (el[index]) {
                    let parent = el[index].parentElement;
                    if (parent) {
                        parent.setAttribute('style', 'border-bottom: 1px solid #e8e8e8;');

                    }
                    if (this.orderService.countryCode === countryCode.code) {
                        el[index].scrollIntoView();
                    }

                    el[index].innerHTML =
                        `
                    <div style="display: flex;">
                    <img style="width: 16px;height: 17px;margin-right: 10px;" src="${imageUrl}">
                    <span>+${countryCode.code}</span>
                    </div>
                `
                        ;
                }
            });
            // this.countryNumbers.map((item, index) => {
            //     let imageUrl = '../../../../../assets/images/flags/' + item.countryCode + '/flat/64.png';
            //     if (el[index]) {
            //         let parent = el[index].parentElement;
            //         if (parent) {
            //             parent.setAttribute('style', 'border-bottom: 1px solid #e8e8e8;');

            //         }
            //         if (this.orderService.countryCode === item.number) {
            //             el[index].scrollIntoView();
            //         }

            //         el[index].innerHTML =
            //             `
            //         <div style="display: flex;">
            //         <img style="width: 16px;height: 17px;margin-right: 10px;" src="${imageUrl}">
            //         <span>+${item.number}</span>
            //         </div>
            //     `
            //             ;
            //     }

            // });

        }, 150);


    }

    ngCountryCode(v){
        this.countryCallingCode = v.detail.value;
        this.countryCodeCb.emit({v: v.detail.value});
    }
    nameChangeHandle() {
        console.log('on componenet name change:', this.name);
        this.orderService.name = this.name;
        this.isNameDirty = true;
        this.nameChange.emit();
    }

    spotChangeHandle() {
        console.log('on componenet spot change:', this.spot);
        this.orderService.spot = this.spot;
    }

    pwhandle(ev) {
        console.log('pwhandle ev', ev);

        if (this.orderService.pw1 !== this.orderService.pw2) {
            let msg = this.t.instant('pages.payment.twice-pw-err');
            this.showAlert('', msg, ['OK'], () => {
                this.pw2Input.setFocus();
            });
        }
    }

    showTAndCReg() {
        console.log('show showTAndCReg');

        this.openTermsModal(this.orderManager.store.brand, (data) => {
            if (data) {
                console.log('GuestInfoComponent openTermsModal res', data);
                // todo change the marking val
                this.orderService.upgradeMemberMarketing = data.marketing;
            }
        }, TermsConditionsPage, true, false);

    }

    showGuestRegistration() {
        let member = this.memberService.getMember();
        return member && member['upgrade'] && this.guestRegistration;
    }
}
