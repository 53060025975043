import { Brand, Store } from 'aigens-ng-core';
import { TermsConditionsPage } from '../../../dialogs/terms-conditions/terms-conditions';
import { MemberService } from '../../../core/services/member.service';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { getCountryCallingCode } from '../../../shared/utilities/checkFormatTool';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { AddressDialog } from 'src/app/dialogs/address-dialog/address-dialog.component';
import { NavParams } from '@ionic/angular';
import { UUID } from 'angular2-uuid';

declare var FB: any;

@Component({
    selector: 'app-delivery-login',
    templateUrl: './delivery-login.page.html',
    styleUrls: ['./delivery-login.page.scss'],
})
export class DeliveryLoginPage extends MobileBasePage implements OnInit, OnDestroy {

    form: any = {};

    status = '';
    fbui: any;
    brand: Brand;
    store: Store;
    loginCallBack: any;
    googleLoginClicked = false;
    googleLoginHandler: any;
    mode = 'email';

    hasCrmId = false;

    isPopup = false;
    fromPage: string;

    facebookLogin = false;
    googleLogin = false;

    public navParams;
    isFirstLoad = true;

    isNewLoginFlow = true;
    isEnterPWUi = false;

    constructor(injector: Injector, public memberService: MemberService) {
        super(injector);

        console.log('delivery login page');
        this.loginCallBack = this.getNavParams('logincb');
        this.store = this.getNavParams('store');
        this.fromPage = this.getNavParams('fromPage');

        if (!this.store) {
            // when this component is using for modal-pop-up ,it can be injector NavParams;otherwise be err;
            this.navParams = injector.get(NavParams);
            this.store = this.navParams.get('store');
        }

        if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway') && !this.getNavParams('isRoute')) {
            //   when this component is not using for modal-pop-up , the next code will be err;
            this.isPopup = this.navParams.get('isPopup');
        }

        this.brand = this.store.brand;
        let member = this.memberService.getMember();
        if (member) {
            if (member['method'] === 'device') {
                // no need logout because of the check phone or email isRegister need sid.
            } else
                this.memberService.clearSession();
        }

        if (this.brand) {
            // this.brand['crmId'] = ""
            this.hasCrmId = this.brand['crmId'] ? true : false;
            this.facebookLogin = this.brand.hasFeature('flogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
            this.googleLogin = this.brand.hasFeature('glogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
        }
        if (this.googleLogin) {
            // this.initGoogle();
            this.googleLoginHandler = (e) => {
                const data = e.detail;
                this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', this.googleLoginHandler);
        }

        // console.log('store:', this.store);

    }

    dismissClicked() {
        console.log('delivery login modal closing');
        this.modalController.dismiss();
    }

    ionViewDidEnter() {
        if (this.isFirstLoad) {
            this.initGoogle();
        }
        this.isFirstLoad = false;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    getCountryCallingCode() {
        return getCountryCallingCode();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        window.removeEventListener('google-login-handling', this.googleLoginHandler);
    }


    submitClicked() {

        console.log('login clicking', this.form);
        const username = (this.form[this.mode] ? this.form[this.mode] : '');
        const password = (this.form['password'] ? this.form['password'] : '');
        let countryCallingCode = this.form['countryCallingCode'];
        const tmpSet = new Set(username);
        let type = tmpSet.has('@') ? 'email' : 'phone';

        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }

        if (this.mode !== type) {
            if (this.mode === 'email') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-email'));
                return;
            }
            if (this.mode === 'phone') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-phone'));
                return;
            }
            return;
        }
        let brandId = null;
        if (this.hasCrmId) {

            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }

            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }

        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(member => {
            this.memberService.putMember(member);
            this.checkingMembership(this.store, () => {
                if (sessionId) {

                    // check membership first
                    if ((!member.membership || member.membership === 0)) {
                        // prompt alert for asking membership
                        this.memberHandle();
                        return;
                    }
                    // delivery new logic
                    // call api to get current member's address
                    if (!member.phone) {
                        this.pushByName('PhoneVerification', {
                            fromPage: DeliveryLoginPage,
                            store: this.store,
                            deliveryLoginCb: () => {
                                this.deliveryHandling();
                            }
                        });
                    } else {
                        this.deliveryHandling();
                    }

                    return;

                    if (this.systemConfigService.hideMemberProfilePage) {
                        this.navigationService.popPage();
                    } else {
                        const page = this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                        const params = { isEditMode: !this.isProfileCompleted(member) };
                        this.pushByName(page, params, { replaceUrl: true }).then(() => {
                            if (!this.isProfileCompleted(member)) {
                                this.showAlert('', this.t.instant('pages.login.incomplete-profile'));
                            }
                        });
                    }

                }
            });
            const sessionId = member.sessionId;


        }, err => {
            this.loading(false);
            console.error(err);
            this.showError(err);
        }, () => {
            this.loading(false);
        });

    }

    memberHandle() {

        this.openTermsModal(this.orderManager.store.brand, (handle) => {
            console.log('handle', handle);

            if (handle['fail'] === 'failed') {

                console.log('focus out');
                this.memberService.clear();
                this.navigationService.popToRoot();

            } else {
                // do membership here
                let marketing = false;
                marketing = handle['marketing'];
                let marketingOrderPlace = false;
                marketingOrderPlace = handle['marketingBrand'];
                console.log('marketing', marketing);
                console.log('will do membership');
                this.loading(true);
                this.memberApiService.join(this.orderManager.store.id, this.orderManager.store.brandId, marketing).subscribe((result) => {
                    console.log('result', result);
                    this.loading(false);

                    if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                        // delivery new logic
                        // call api to get current member's address
                        if (!this.memberService.member.phone) {
                            this.pushByName('PhoneVerification', {
                                fromPage: DeliveryLoginPage,
                                store: this.store,
                                deliveryLoginCb: () => {
                                    this.deliveryHandling();
                                }
                            });
                        } else {
                            this.deliveryHandling();
                        }
                    }

                }, (err) => {
                    this.loading(false);
                });
            }

        }, TermsConditionsPage, true, false, true, this.memberService.member.marketing, false, false, (callBack) => {
            this.showAlert('', this.t.instant('global.t&c-dimiss-reminder'), [{
                text: this.t.instant('global.t&c-logout'), // 'Log out '
                handler: () => {
                    callBack();
                }
            }, {
                text: this.t.instant('global.t&c-cancel'), // 'stay'
                handler: () => {
                }
            }]);
        });
    }

    deliveryHandling() {
        let needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        let fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        this.loading(true);
        this.orderSessionService.getOrderSession(this.orderManager.store.id, this.orderManager.mode, fields).subscribe(orderSession => {
            this.loading(false);
            if (orderSession) {
                let member = orderSession.member;
                if (this.memberService.member && this.memberService.member.phone) {
                    orderSession.member['phone'] = this.memberService.member.phone;
                    orderSession.member.marketing = this.memberService.member.marketing;
                }
                this.orderSessionService.setLocalAddress(this.addressManagerService.calAddressesDistance(member.addresses, this.store));

                this.configService.setLocal('orderSession', orderSession);
                if (member.addresses && member.addresses.length > 0) {
                    let addresses = member.addresses;
                    this.addressManagerService.addressArr = addresses;
                    let addressArrBeyond = addresses.filter(address => !address['beyond']);
                    if (addressArrBeyond.length >= 1) {
                        if (!(this.addressManagerService.pickupAddress || this.addressManagerService.currentAddress)) {
                            let defautlAddress = this.addressManagerService.findDefaultAddress(addressArrBeyond);
                            this.addressManagerService.saveCurrentAddress(defautlAddress);
                        }
                        if (this.fromPage === 'CategoryListPage' || this.fromPage === 'ItemGridScrollPage') {
                            this.backButtonClick();
                            // before forcing login , save the order message with Tourists,so need to clear the local object
                            const key = this.store.id + '.savedOrder';
                            this.configService.putPreference(key, null);
                            return;
                        }
                        this.popToRoot().then(() => {
                            if (this.loginCallBack) {
                                this.loginCallBack();
                            }
                        });
                    } else {
                        let page = 'AddressDialog';
                        this.pushByName(page, {
                            fromPage: 'deliveryLogin',
                            isRoute: true,
                            goToMenu: true,
                            showModeSelect: true,
                            // loginCb: () => {
                            //     if (this.loginCallBack)
                            //         this.loginCallBack();
                            // }
                        }, {}).then(() => {

                        });
                    }
                } else {
                    let page = 'AddressDialog';
                    this.pushByName(page, {
                        fromPage: 'deliveryLogin',
                        isRoute: true,
                        goToMenu: true,
                        showModeSelect: true,
                        // loginCb: () => {
                        //     if (this.loginCallBack)
                        //         this.loginCallBack();
                        // }
                    }, {}).then(() => {
                        this.showToast('Please add yout first address', 2000, 'top', 'normal-toast');
                    });
                }
            } else {
                return;
            }
        }, err => {
            this.loading(false);
            console.log('getReward error', err);
        });
    }

    onFacebookLoginClick() {
        this.loading(true);

        FB.login((response) => {
            this.loading(false);

            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                const data = response.authResponse;
                this.fbLoginHandling(data['userID'], data['accessToken'], true);

            } else {
                this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, { auth_type: 'reauthenticate' });

    }

    fbLoginHandling(fbId: string, token: string, join: boolean = false) {
        this.memberApiService.FBloginOrRegistration(fbId, token, true, this.store.id, this.brand.id, true).subscribe((member) => {
            console.log(member);
            this.loading(false);

            const sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {
                this.memberService.putMember(member);
                this.checkingMembership(this.store, () => {
                    if (!member.membership || !member.membership.marketing) {

                        this.openTermsModal(this.brand, (data) => {
                            // this.configService.putPreference('termsAgree', data['agree']);
                            this.memberApiService.join(this.orderManager.store.id, this.orderManager.store.brandId, data.marketing).subscribe((result) => {
                                this.loading(false);
                            }, err => {
                                console.log(err);
                                this.loading(false);
                                this.showAlert('Error ' + err.status, err._body);
                            }, () => {
                                this.memberService.member.marketing = data.marketing;
                                if (member.phone) {
                                    this.deliveryHandling();
                                } else {
                                    this.pushByName('PhoneVerification', {
                                        fromPage: DeliveryLoginPage,
                                        store: this.store,
                                        deliveryLoginCb: () => {
                                            this.deliveryHandling();
                                        }
                                    });

                                }
                            });
                        }, TermsConditionsPage, true, true);
                    } else {

                        // force to input phone
                        if (member.phone) {
                            this.deliveryHandling();
                        } else {
                            this.pushByName('PhoneVerification', {
                                fromPage: DeliveryLoginPage,
                                store: this.store,
                                deliveryLoginCb: () => {
                                    this.deliveryHandling();
                                }
                            });

                        }
                    }
                });


                // deal with the delivery condition with fblogin
                // this.deliveryHandling();
                return;

                const page = this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                const params = { isEditMode: !this.isProfileCompleted(member) };
                this.pushAndRemovePage(page, params, null, () => {
                    if (!this.isProfileCompleted(member)) {
                        this.showAlert('', this.t.instant('pages.login.incomplete-profile'));
                    }
                });
            }
        }, (err) => {
            console.log(err);
            this.showError(err);
        });



    }


    registration() {
        this.pushByName('VerifyRegistrationPage', { fromPage: DeliveryLoginPage, store: this.store });
    }

    resetPassword() {
        let store = this.orderManager.store;
        if (!store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        } else {
            this.pushByName('ResetPasswordPage', { username: this.form.email, isForgotPassword: true, brand: this.brand });
        }
    }

    initGoogle() {
        window['onGoogleSignIn'] = this.onSignIn;
        const params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js?onload=renderButton', () => {
        }, params);
    }


    onSignIn(googleUser) {
        const profile = googleUser.getBasicProfile();
        const id = profile.getId();
        const id_token = googleUser.getAuthResponse().id_token;

        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        const evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });

        window.dispatchEvent(evt);

    }

    clickGoogleLogin() {
        this.googleLoginClicked = true;
    }

    selectMode(type: string) {
        this.mode = type;
    }

    googleLoginHandling(id: string, token: string) {
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(
                member => {
                    console.log(member);
                    this.loading(false);
                    const sessionId = member.sessionId;
                    if (sessionId) {
                        this.memberService.putMember(member);
                        this.checkingMembership(this.store, () => {
                            if (!member.membership || !member.membership.marketing) {
                                this.openTermsModal(this.brand, (data) => {
                                    this.loading(true);
                                    this.memberApiService.join(this.orderManager.store.id, this.orderManager.store.brandId, data.marketing).subscribe((data) => {
                                        this.loading(false);
                                    }, err => {
                                        console.log(err);
                                        this.loading(false);
                                        this.showAlert('Error ' + err.status, err._body);
                                    }, () => {
                                        // this.configService.putPreference('termsAgree', data['agree']);
                                        this.memberService.member.marketing = data.marketing;
                                        if (member.phone) {
                                            this.deliveryHandling();
                                        } else {
                                            this.pushByName('PhoneVerification', {
                                                fromPage: DeliveryLoginPage,
                                                store: this.store,
                                                deliveryLoginCb: () => {
                                                    this.deliveryHandling();
                                                }
                                            });

                                        }
                                    });

                                }, TermsConditionsPage, true, true);
                            } else {
                                if (member.phone) {
                                    this.deliveryHandling();
                                } else {
                                    this.pushByName('PhoneVerification', {
                                        fromPage: DeliveryLoginPage,
                                        store: this.store,
                                        deliveryLoginCb: () => {
                                            this.deliveryHandling();
                                        }
                                    });

                                }
                            }
                            return;

                        });
                        // this.push(MemberRewardPage);
                    }
                },
                err => {
                    this.loading(false);
                    console.error(err);
                    this.showError(err);
                },
                () => {
                    this.loading(false);
                }
            );

        }
    }
    justShowTC() {
        this.openTermsModal(this.brand, () => { }, TermsConditionsPage, false, false, false, false, true, true);
    }

    guestLogin() {
        this.loading(true);
        let s = UUID.UUID().toString();
        this.memberApiService.guestLogin(s, this.store.brandId).subscribe((member) => {
            this.loading(false);
            this.memberService.putMember(member);
            this.deliveryHandling();
        }, err => {
            this.loading(false);
            this.showError(err);
        });
    }

    newLoginFlowNext() {
        console.log('newLoginFlowNext');
        let params = {};
        params[this.mode] = this.form[this.mode];
        // if (this.mode === 'email') {
        //     console.log('mode', this.mode);
        //     params['phone'] = this.form[this.mode];
        // }

        // if (this.mode === 'phone') {
        //     console.log('mode', this.mode);
        //     params['phone'] = this.form[this.mode];
        // }
        console.log('params', params);

        this.getIsRegister(params);

    }

    getIsRegister(params) {
        console.log("getIsRegister params", params);
        // maybe need to change the api to getIsRegister
        this.loading(true);
        this.memberApiService.getIsRegister(this.orderManager.store.brandId, this.orderManager.store.id, params).subscribe((res) => {
            // isRegister = true
            this.loading(false);
            if (res && res.exist && res.exist[this.mode]) {
                console.log("已经注册");
                this.changeToEnterPW();
            } else {
                this.gotoRegisterPage();
            }
        }, err => {
            console.log('getIsRegister res',err);
            this.loading(false);
            this.showError(err);
            // if(err.error === 'PHONE_ALREADY_EXIST' || err.error === 'EMAIL_ALREADY_EXIST'){
            //     // isRegister = true
            //     this.changeToEnterPW();
            // }else{
            //     // isRegister = true
            //     this.gotoRegisterPage();
            // }
        });
        // not register
        // this.gotoRegisterPage();
        
        // already registered
        // this.changeToEnterPW();

    }

    changeToEnterPW() {
        console.log("changeToEnterPW");
        this.isEnterPWUi = true;
    }

    gotoRegisterPage() {
        console.log("gotoRegisterPage");
        this.pushByName('VerifyRegistrationPage', { fromPage: DeliveryLoginPage, store: this.store });
    }

    backButtonClick() {
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        } else {
            super.backButtonClick();
        }
    }

}
