import { Data, Discount, Member, Offer, Order, OrderManager, Period, Spot, Store, OrderItem, Brand } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MemberService } from './member.service';
import { TranslateService } from '@ngx-translate/core';
import { version } from '../../../../package.json';
import { ActivatedRoute } from '@angular/router';
import { CartService } from './cart.service';
import { SdkConfigService } from './sdk-config-service';
import { UUID } from 'angular2-uuid';
import { SystemConfigService } from './systemConfig.service';
import { AddressManagerService } from './address-manager.service';
import { getCountryCallingCode } from 'src/app/shared/utilities/checkFormatTool';


@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {

    aq: AQuery;

    email: string;
    // duplicate with orderManager
    // member: any = {};

    firstName: string;
    lastName: string;
    address: any = {};
    phone: string;
    spot: string;
    session: string;
    pickUpTime: string;
    pickUpLabel: string;
    maxPickupTime: string;
    uuid: string;
    currency = 'HKD';
    screenWidthUnit: number;
    selectedDineinTakeoutPrompt = 'firstView';
    sign = '$';
    courtId: number;
    courtBrand;
    crmId: string;
    pw1: string;
    pw2: string;
    /**
     *  mode option:
     *  read from url
     *  dinein
     *  takeaway
     *  prekiosk
     *  preorder
     *  if pickup: popup alert to select dinein/takeaway
     *  if no,
     *  byod
     *
     *
     * */
    selfPickUp = false;
    delivery = false;
    takeout = false;
    isBK = false;
    // TODO: duplicate with orderManager
    store: Store;
    storeId: number;
    deviceId: string;
    currentPeriod: Period;
    hiddenNav = false;
    hideTotal = false;
    comeInHasSession = false;
    hasServiceChargeOrTax = false;
    isCourt = false;
    preOrder = false;
    chargeDesc: string;
    selectedReward: Offer;
    selectedReward_court: Offer;
    selectedPromotions_court: Discount[] = [];
    // promotions_court : Discount[];
    passcode;
    login = false;
    isStaff = false;
    isHomeButton = false;
    surveyUrl: string;
    surveyExtBrowser: boolean;
    orderExpire: number;
    countryCode: any;
    settings = {};
    promoCodeGift = [];
    seatsGift = [];
    sourceGift = [];
    stores: Store[];

    gaTrack = {
        'cate-to-grid-level': 1,
        'grid-to-cart-level': 1
    };

    seats: number = null;

    emailReceipt = true;
    phoneNotify = true;

    modeMap = {
        'delivery': { needPhone: true, needEmail: false },
        'takeaway': { needPhone: true, needEmail: false },
        'dinein': { needPhone: false, needEmail: false },
    };

    // coupon map
    couponMap: any = {
        'coupon': null,
        'couponDisplay': null,
        'couponBenefit': null,
        'couponConds': [],
        'canShowCouponInput': true, // for reset coupon
        'useCoupon': false,
        'couponMenuDiscounts': null,
        'canUseCoupon': false,
    };
    name: string = null;
    generalGift: any = [];
    marketing1 = false;
    marketing2 = false;
    demoPickupTime: any;

    upgradeMemberMarketing = true;
    private useServerDiscount = false;

    courtOrderDiscount: any;

    constructor(private http: HttpClient, public cartService: CartService, public orderManager: OrderManager, public route: ActivatedRoute, public memberService: MemberService, public configs: ConfigService, public translate: TranslateService, public sdkConfigService: SdkConfigService, public systemConfigService: SystemConfigService, public addressManager: AddressManagerService) {
        super();

        this.aq = new AQuery(http, configs);
    }

    get hasCrm(): boolean {
        // const crm = (this.orderManager.store && this.orderManager.store.brand && this.orderManager.store.brand.crmId) || null;
        let crm = null;
        if (this.orderManager.store && this.orderManager.store.crm) {
            crm = !this.orderManager.store.crm['disabled'];
        }
        return !(!crm);
    }

    setStore(store: Store) {
        this.store = store;
        this.setCurrency(store.currency);
        const tmpSettings = store.menu && store.menu.settings ? store.menu.settings : null;
        if (tmpSettings) {
            this.settings[store.id] = tmpSettings;
        }

        this.hasServiceChargeOrTax = true; // store.menu && store.menu.settings ? (store.menu.settings.serviceCharge > 0 || store.menu.settings.tax > 0) : false;
        this.chargeDesc = store.menu && store.menu.settings ? store.menu.settings.chargeDesc : '';

        // if (store.hasFeature('kiosk') && !this.spot && !this.preOrder) {
        //     this.orderManager.mode = 'prekiosk';
        // } else if (store.hasFeature('byod')) {
        //     this.orderManager.mode = 'byod';
        // }

    }

    setCurrency(currency: string) {
        if ('HKD' === currency) {
            this.sign = '$';
        } else if ('CNY' === currency) {
            this.sign = '¥';
        } else if ('SGD' === currency) {
            this.sign = 'S$';
        } else if ('MYR' === currency || 'RM' === currency) {
            this.sign = 'RM';
        } else if ('IDR' === currency) {
            this.sign = 'Rp';
        } else if (!currency) {
            currency = 'HKD';
            this.sign = '$';
        }

        this.currency = currency;

        if (this.store && this.store.currencySign) {
            this.sign = this.store.currencySign;
        }
    }

    getCurrentStoreSetting() {
        if (!this.store) {
            return null;
        }
        return this.settings[this.store.id];
    }

    clear() {
        this.pickUpLabel = null;
        this.pickUpTime = null;
        this.maxPickupTime = null;
        this.selectedReward = null;
        this.selectedReward_court = null;
    }

    clearCoupon() {
        this.couponMap = {
            'coupon': null,
            'couponDisplay': null,
            'couponBenefit': null,
            'couponConds': [],
            'canShowCouponInput': true, // for reset coupon
            'useCoupon': false,
            'couponMenuDiscounts': null,
            'canUseCoupon': false,
        };
    }

    clearPromoCodeGift() {
        this.promoCodeGift = [];
    }

    postOrder(order: Order): Observable<Order> {
        console.log('post');
        if (this.orderManager.mode === 'prekiosk') {
            return this.postPreKioskOrder(order);
        }

        if (this.orderManager.mode === 'byod') {
            return this.postByodOrder(order);
        }
    }

    postMultiOrder(orders: Order[], token: string): Observable<Order[]> {
        const url = '/api/v1/menu/orders.json?type=byod';


        console.log('orders', orders);

        const body = {};
        const headers = {};
        // orders.forEach((order) => {
        //     order.takeout = "takeaway" === this.orderManager.mode;
        // })
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);
        console.log('body', body);


        for (const order of body['orders']) {
            order['type'] = 'byod';
            order['session'] = this.session; // "1111";
            order['deviceId'] = this.session;
            order['takeout'] = 'takeaway' === this.orderManager.mode;
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        body['token'] = token;
        body['payment'] = 'stripe';
        body['email'] = this.email;
        if (!this.pickUpTime || this.pickUpTime === '0') {
            body['pickupTime'] = null;
        } else {
            body['pickupTime'] = Number(this.pickUpTime);
        }

        if (this.phone) {
            body['phone'] = this.phone;

        }


        console.log('body', body);

        const aq = this.aq;

        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    postPreKioskOrder(order: Order): Observable<Order> {

        const url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        const body = Order.toOrderData(order); // this.slimOrder(order);

        body['type'] = 'prekiosk';
        body['session'] = this.session;
        body['takeout'] = 'takeaway' === this.orderManager.mode;

        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    postByodOrder(order: Order): Observable<Order> {

        const url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        const body = Order.toOrderData(order); // this.slimOrder(order);
        const headers = {};
        const member = this.memberService.getMember();


        if (this.cartService.enable) {
            const t = {
                uuid: this.memberService.uuid,
                name: this.memberService.name,
                lockTime: Date.now()
            };
            body['lock'] = t;
        }
        body['type'] = 'byod';
        body['session'] = this.session;
        body['spot'] = this.spot;
        body['deviceId'] = this.deviceId;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['pickUpTime'] = this.pickUpTime;
        if (this.addressManager.note) {
            body['note'] = this.addressManager.note;
        }
        if (this.passcode) {
            body['passcode'] = this.passcode;

        }
        if (this.systemConfigService.isBatchOrder) {
            body['batchId'] = UUID.UUID().toString();
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }


        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        if (member) {
            aq.auth(true);
        }


        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    postOrderWithPaymentObject(order: Order, paymentObject?: any) {
        let url = '/api/v1/menu/order.json';

        let groupId = this.configs.getGroupId();
        if (Number(groupId) === 50000) {
            // extra handling for CDC
            url = '/api/v1/cdc/order.json';
        }
        let body = {};
        // order.takeout = "takeaway" === this.orderManager.mode;
        body = Order.toOrderData(order); // this.slimOrder(order);
        console.log('body', body);


        body['session'] = this.session;
        body['storeId'] = order.storeId;
        body['deviceId'] = this.session;
        body['takeout'] = order.takeout;

        body['type'] = this.orderManager.mode;
        if ('delivery' === this.orderManager.mode || 'takeaway' === this.orderManager.mode) {
            if (this.addressManager.currentAddress && this.addressManager.currentCourier) {
                body['addressId'] = this.addressManager.currentAddress['id'];
                body['courierId'] = this.addressManager.currentCourier['id'];

            }
            if (this.addressManager.isPickup) {
                if (this.pickUpTime) {
                    body['pickupTime'] = this.pickUpTime;
                }
                body['type'] = 'takeaway'; // pickupstore 用takeaway
            }
            body['note'] = this.addressManager.note;
            body['cutlery'] = this.addressManager.cutlery;
            body['contactless'] = this.addressManager.contactless;
            if (this.name) {
                body['username'] = this.name;
            }

        }
        body['uuid'] = this.uuid;
        body['userToken'] = this.configs.getUserToken();


        console.log('JJ: OrderService -> postOrderWithPaymentObject -> this.sdkConfigService.isCrystalJade', this.sdkConfigService.getMemberCardNo);
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        if (order.id) {
            // for the case which need to reuse the order.id: apply discountCode and pay.
            body['id'] = order.id;
        }

        // TODO: should use order.member
        const member = this.memberService.getMember();

        // if (this.isCourt || member) {
        //     body["member"] = this.member;
        //     if (member) {
        //         headers["sid"] = member.sessionId;
        //     }
        // }

        if (this.isCourt) {
            body['member'] = member;
        } else {
            if (member && this.hasCrm) {
                body['member'] = Member.toMemberData(member);

            }
        }

        if (body['member']) {
            if (this.name) {
                body['member']['lastName'] = this.name;
            }
        } else {
            if (this.name) {
                let m = {
                    'lastName': this.name
                };
                body['member'] = m;
            }
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;

        } else {
            // body['email'] = 'aigensemail@gmail.com'; // dummy email no longer used;

        }

        if (this.pickUpTime && 'delivery' !== this.orderManager.mode) {
            body['pickupTime'] = this.pickUpTime;
        }

        if (this.phone) {
            body['phone'] = this.phone;
            body['countryCode'] = '+' + this.countryCode;
            // body['phone'] = this.phone;
            // if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')){
            //     body['phone'] = this.phone;
            //     body['countryCode'] = '+' + this.countryCode;
            // }
        }

        if (this.spot) {
            body['spot'] = this.spot;
        }

        if (paymentObject) {
            body['charge'] = paymentObject;
        }

        body['version'] = version;


        if (this.couponMap['coupon'] && this.couponMap['useCoupon'] && this.couponMap['canUseCoupon']) {
            body['coupon'] = this.couponMap['coupon'];
        }

        if (this.courtId) {
            body['courtId'] = Number(this.courtId);
        }

        body['emailReceipt'] = this.email && this.emailReceipt;
        body['phoneNotify'] = this.phoneNotify;
        body['trackerUrl'] = window.location.origin + '/order/@orderId';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;


        return aq.auth(true).getJson().pipe(map(jo => {
            // save the val to order successly
            this.configs.setLocal('backStorelistRouteErr', true);
            return jo;
        }));
    }

    postOrderWithOnlyReward(order: Order, member: Member, offer: any) {

        let url = '/api/v1/menu/order.json';

        let groupId = this.configs.getGroupId();
        if (Number(groupId) === 50000) {
            // extra handling for CDC
            url = '/api/v1/cdc/order.json';
        }
        let body = {};
        // order.takeout = "takeaway" === this.orderManager.mode;
        body = Order.toOrderData(order); // this.slimOrder(order);
        console.log('body', body);

        body['session'] = this.session;
        body['storeId'] = order.storeId;
        // body['deviceId'] = this.session;
        body['deviceId'] = this.deviceId;
        body['takeout'] = order.takeout;

        body['type'] = this.orderManager.mode;
        if ('delivery' === this.orderManager.mode || 'takeaway' === this.orderManager.mode) {
            if (this.addressManager.currentAddress && this.addressManager.currentCourier) {
                body['addressId'] = this.addressManager.currentAddress['id'];
                body['courierId'] = this.addressManager.currentCourier['id'];

            }
            if (this.addressManager.isPickup) {
                if (this.pickUpTime) {
                    body['pickupTime'] = this.pickUpTime;
                }
                body['type'] = 'takeaway'; // pickupstore 用takeaway
            }
            body['note'] = this.addressManager.note;
            body['cutlery'] = this.addressManager.cutlery;
            body['contactless'] = this.addressManager.contactless;
            if (this.name) {
                body['username'] = this.name;
            }

        }
        body['uuid'] = this.uuid;
        body['userToken'] = this.configs.getUserToken();

        if (order.id) {
            // for the case which need to reuse the order.id: apply discountCode and pay.
            body['id'] = order.id;
        }

        if (this.isCourt) {
            body['member'] = member;
        } else {
            if (member && this.hasCrm) {
                body['member'] = Member.toMemberData(member);

            }
        }

        if (body['member']) {
            if (this.name) {
                body['member']['lastName'] = this.name;
            }
        } else {
            if (this.name) {
                let m = {
                    'lastName': this.name
                };
                body['member'] = m;
            }
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;

        } else {
            // body['email'] = 'aigensemail@gmail.com'; // dummy email no longer used;

        }

        if (this.pickUpTime && 'delivery' !== this.orderManager.mode) {
            body['pickupTime'] = this.pickUpTime;
        }

        if (this.phone) {
            body['phone'] = this.phone;
            body['countryCode'] = '+' + this.countryCode;
        }

        if (this.spot) {
            body['spot'] = this.spot;
        }

        // if(paymentObject) {
        //     body['charge'] = paymentObject;
        // }

        body['version'] = version;


        if (this.couponMap['coupon'] && this.couponMap['useCoupon'] && this.couponMap['canUseCoupon']) {
            body['coupon'] = this.couponMap['coupon'];
        }

        if (this.courtId) {
            body['courtId'] = Number(this.courtId);
        }

        body['emailReceipt'] = this.emailReceipt;
        body['phoneNotify'] = this.phoneNotify;
        body['trackerUrl'] = window.location.origin + '/order/@orderId';

        for (let orderitem of order.orderitems) {
            var jsonBody = {};
            var category = {};
            jsonBody["name"] = orderitem.categoryId;
            jsonBody["id"] = orderitem.categoryId;

            category["category"] = jsonBody;

            orderitem["category"] = category["category"];

        }

        // body = Order.toOrderData(order)//this.slimOrder(order);

        //body = this.slimOrder(order);

        if (order.takeout) {
            body["type"] = "takeaway";
        } else {
            body["type"] = "dinein";
        }

        body["preorder"] = order.id;
        body["orderNo"] = order.orderNo;

        // copy form cdc 
        if (offer) {
            var offerArray: Offer[] = [];
            if (Array.isArray(offer)) {

            } else {
                offer = [offer];
            }
            for (let o of offer) {
                let benefitAmount;
                let benefitQuantity;
                if (o.reward && o.reward.discount && o.reward.discount.benefits && o.reward.discount.benefits[0]) {
                    benefitAmount = o.reward.discount.benefits[0].amount;
                    benefitQuantity = o.reward.discount.benefits[0].max;

                    o.reward.discount.amount = benefitAmount;
                    o.reward.discount.quantity = benefitQuantity;
                }

                offerArray.push(o);

            }
            body["offers"] = offerArray;

        } else {
            body["offers"] = null;
        }

        console.log("postOrderWithPaymentObject", body);

        var aq = this.aq;
        aq.url = url;
        aq.method = "post";
        aq.body = body;

        return aq.auth(true).getJson();
    }

    getBKUserInfo(sid): Observable<Member> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/member.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';

        return aq.auth(true).getJson().pipe(map(jo => Data.toData(Member, jo['data'])));
    }

    postBKOrder(order: Order, paymentObject: any, isNetspay = false): Observable<Order> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        let server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';

        if (isNetspay) {
            server = isPrd ? 'https://bksgapp.appspot.com' : 'https://nets-dot-bksgtest.appspot.com'; // test nets pay
        }
        const url = server + '/api/v1/bk/order.json?menu=' + this.orderManager.mode;
        // order.takeout = "takeaway" === this.orderManager.mode;

        const body = Order.toOrderData(order); // this.slimOrder(order);
        // body["storeId"] = 5741421214564352;
        // body["type"] = "delivery";
        body['station'] = 'mobile';
        body['test'] = !isPrd; // when prd it should be false
        body['note'] = this.address['comment'];
        body['charge'] = paymentObject;
        // body["payment"] = "stripe";
        body['session'] = this.session;
        body['delivery'] = 'delivery' === this.orderManager.mode;
        // body["takeout"] = false;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['email'] = this.email.toLowerCase();

        if (this.pickUpTime && (this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
            body['deliveryTime'] = this.pickUpTime;
        } else if (this.pickUpTime && this.orderManager.mode !== 'delivery') {
            body['pickupTime'] = this.pickUpTime;
        }


        if (this.firstName && this.lastName) {
            body['firstName'] = this.firstName;
            body['lastName'] = this.lastName;
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }

        this.address['city'] = 'Singapore';
        this.address['country'] = 'SG';
        body['address'] = this.address;

        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    getBKReward(): Observable<Offer[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/reward/offer.json';
        const params = {};
        params['sid'] = this.session;


        console.log('params', params);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'])));

    }

    getReward(brandId: any = null): Observable<Offer[]> {
        let bId = brandId ? brandId : this.store.brandId;
        let url = '/api/v1/menu/offer.json?brandId=' + bId;
        if (this.store) {
            url = url + '&storeId=' + this.storeId;
        }
        const member = this.memberService.getMember();

        // let storeId = this.orderManager.store.id;
        // let mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId,mode,'offers');
        // url = url + '&brandId=' + this.store.brandId + '&storeId=' + this.storeId;
        // todo change new api return val to old api val form

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'])));

    }
    getCourtReward(): Observable<Offer[]> {
        const url = '/api/v1/menu/offer.json?courtId=' + this.courtId;
        const member = this.memberService.getMember();

        // let storeId = this.orderManager.store.id;
        // let mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId,mode,'offers');
        // url = url + '&courtId=' + this.courtId;
        // todo change new api return val to old api val form

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'])));

    }

    getCourtRewardCheck(offerId: string | number, action: 'unhold' | 'hold'): Observable<any> {
        const url = '/api/v1/menu/offer.json?courtId=' + this.courtId + '&action=' + action;
        const member = this.memberService.getMember();

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        if (member) {
            aq.auth(true);
        }
        let body = {};
        let offer = {
            id: offerId
        };

        body['offers'] = [offer];
        aq.body = body;
        return aq.getJson().pipe(map(jo => jo['data']));
    }

    checkOTP(passcode: string): Observable<boolean> {
        const url = '/api/v1/menu/spot.json'; // "/api/v1/store/otpverify.json?storeId=" + this.storeId +"&passcode=" + passcode;
        const params = {};
        params['action'] = 'verify';
        params['name'] = this.spot;
        params['storeId'] = this.storeId;
        params['passcode'] = passcode;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo['vefified']));
    }

    getSpots(storeId: any): Observable<Spot[]> {

        const url = '/api/v1/menu/spot.json';

        const params = {};
        params['storeId'] = storeId;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Spot, jo['data'])));
    }

    checkSpot(storeId: any, spot: string, deviceId: string, code?: string): Observable<any> {

        const url = '/api/v1/menu/spot.json';

        const params = {};
        params['storeId'] = storeId;
        params['spot'] = spot;
        params['deviceId'] = deviceId;
        if (code) {
            params['code'] = code;

        }
        params['check'] = true;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson();
    }

    getByodOrders(storeId: any, spot: string, deviceId: string): Observable<Order[]> {
        let url = '/api/v1/menu/history.json';

        if ('byod' === this.orderManager.mode) {
            url = '/api/v1/menu/order.json';
        }

        const params = {};

        if (this.session) {
            params['session'] = this.session;
        } else if (deviceId) {
            params['session'] = deviceId;
        }

        if (spot) {

            params['spot'] = spot;
            if (deviceId) {
                params['deviceId'] = deviceId;
            }

        }

        if (this.orderManager.mode !== 'delivery' && this.orderManager.mode !== 'pickup') {
            params['type'] = this.orderManager.mode;
        }
        params['storeId'] = storeId;


        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    getOrders(session: string, partial: boolean = false, limitTo: number = 10, limitFrom: number = 0, since: number = null): Observable<any> {
        let url = '/api/v1/menu/order.json';


        const params = {};
        if (this.isCourt) {
            params['type'] = 'court';
            params['courtId'] = this.courtId;
        }
        params['session'] = session;
        params['fields'] = 'store,brand,location';
        params['locale'] = this.translate.currentLang;
        if (partial) {
            params['since'] = since;
            params['limitFrom'] = limitFrom;
            params['limitTo'] = limitTo;

        }


        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => jo['data']));
    }

    getBKOrders(session: string): Observable<Order[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/menu/order.json';

        const params = {};
        params['session'] = session;
        // params['fields'] = "store,brand";
        params['locale'] = this.translate.currentLang;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    getOrderBySession(id: string, session: string = null): Observable<Order> {

        const url = '/api/v1/menu/order/' + id + '.json';
        const params = {};
        params['session'] = session;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));
    }

    getBKStore(postal: number): Observable<Store[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/store.json?postal=' + postal;

        const aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(jo => {
            this.address.latitude = jo['data']['latitude'];
            this.address.longitude = jo['data']['longitude'];

            return Data.toDataArray(Store, jo['data']['stores']);
        }));
    }

    postMultiOrderWithPaymentObject(orders: Order[], paymentObject: any): Observable<Object> {
        // var url = "https://payment-dot-aigensstoretest.appspot.com/api/v1/menu/orders.json";
        console.log('postMultiOrderWithPaymentObject');
        let url: string;
        const mode = (this.isCourt) ? 'court' : this.orderManager.mode;
        if (this.memberService.getMember() && this.memberService.crmId) {
            url = '/api/v1/menu/orders.json?type=' + mode + '&courtId=' + this.courtId + '&sid=' + this.memberService.crmId;
        } else {
            url = '/api/v1/menu/orders.json?type=' + mode + '&courtId=' + this.courtId;
        }

        const body = {};
        const headers = {};
        // orders.forEach((order) => {
        //     order.takeout = "takeaway" === this.orderManager.mode;
        // })
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);

        if (this.isCourt) {
            if (this.selectedReward_court && this.memberService.getMember()) {
                body['offers'] = [this.selectedReward_court];
            }
            if (this.courtOrderDiscount) {
                body['discounts'] = this.courtOrderDiscount.discounts;
                body['discount'] = this.courtOrderDiscount.discount;
                body['promoCodes'] = this.courtOrderDiscount.promoCodes;
            }
        }
        body['phoneNotify'] = this.phoneNotify;

        for (const order of body['orders']) {
            order['type'] = this.orderManager.mode;
            order['session'] = this.session; // "1111";
            order['deviceId'] = this.session;
            order['takeout'] = 'takeaway' === this.orderManager.mode;
            if (this.courtBrand && this.courtBrand.crmId) {

                order['crmId'] = this.courtBrand.crmId;
            }
            if (this.isCourt) {
                order['member'] = this.memberService.member;
            }

            if (this.spot) {
                order['spot'] = this.spot;
            }

            if ('delivery' === this.orderManager.mode && this.addressManager.currentAddress) {
                order['addressId'] = this.addressManager.currentAddress['id'];
            }

            if (order['member']) {
                if (this.name) {
                    order['member']['lastName'] = this.name;
                }
            } else {
                if (this.name) {
                    let m = {
                        'lastName': this.name
                    };
                    order['member'] = m;
                }
            }

        }

        if (this.email && this.email !== '') {
            body['email'] = this.email;
        }

        if (!this.pickUpTime || this.pickUpTime === '0') {
            body['pickupTime'] = null;
        } else {
            let pickUpTime: number = Number(this.pickUpTime);
            if (this.pickUpTime.length < 11) {
                pickUpTime = Number(this.pickUpTime) * 1000;
            }
            body['pickupTime'] = pickUpTime;
        }

        if (this.phone) {
            body['phone'] = this.phone;

        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }


        body['charge'] = paymentObject;
        body['version'] = version;
        body['deviceId'] = this.deviceId;

        console.log('body', body);
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;


        return aq.auth(true).getJson();

    }

    orderChangeStatus(status: string, orderId: string): Observable<Order> {
        const url = '/api/v1/menu/order/' + orderId + '.json';
        const params = {};
        params['status'] = status;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    verify(orderId: string): Observable<any> {
        const url = '/api/v1/bk/order/pickup.json';
        const aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'post';
        // var params = { "orderId": orderId, 'passcode': passcode };
        const params = { 'orderId': orderId };
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo['data']));
    }

    checkingMPGSStatus(id: string, paymentId: string, action?: string) {
        const url = '/api/v1/pay/detail.json';
        const params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;

        if (action) {
            params['action'] = action;
        }
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson();
    }

    checkingOrderStatus(id: string, paymentId: string, action?: string) {
        const url = '/api/v1/pay/success.json';
        const params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;

        if (action) {
            params['action'] = action;
        }
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson();
    }

    checkingOrderStatusWithUrl(url: string) {
        const tmpUrl = url ? url : '';

        const aq = this.aq;
        aq.url = tmpUrl;
        aq.method = 'get';

        return aq.getJson();
    }


    // 从v1搬过来的code
    editHeadCount(): Observable<any> {
        let url = '/api/v1/menu/spot.json';

        let params = {};
        params['name'] = this.spot;
        params['seats'] = this.seats;
        params['key'] = this.session;
        params['action'] = 'edit';
        params['storeId'] = this.store.id;
        let aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return this.aq.auth(true).getJson().map(jo => Data.toDataArray(Order, jo['data']));

    }

    postCouponCode(code: string, brandId: number, storeId: number): Observable<any> {
        let url = '/api/v1/reward/validatecoupon.json';

        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = {
            'code': code,
            'brandId': brandId,
            'type': 'byod',
            'storeId': storeId
        };
        if (this.courtId) {
            aq.params['courtId'] = this.courtId;
        }
        return aq.getJson().map(jo => Data.toData(Discount, jo['data']));
    }

    closeOrder(session: string, orderId: string) {

        if (this.session && orderId) {
            let url = `/api/v1/menu/close.json?session=${this.session}`;

            let body = {};
            body['orderId'] = orderId;

            let aq = this.aq;
            aq.url = url;
            aq.method = 'post';
            aq.body = body;

            return aq.getJson();
        }

    }

    verifyPay(chargeId, paymentConfigId, body): Observable<Order[]> {
        let url = `/api/v1/pay/verify.json?payment=${paymentConfigId}&chargeId=${chargeId}&shouldRedirect=false`;
        // url += `&response-base64=${body['response-base64']}&response-signature-algorithm=${body['response-signature-algorithm']}&response-signature-base64=${body['response-signature-base64']}`;
        let aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().map(jo => Data.toDataArray(Order, jo['data']));
    }

    setUseServerDiscount(bool: boolean) {
        this.useServerDiscount = bool;
    }

    getUseServerDiscount(): boolean {
        return this.useServerDiscount;
    }

    calculateOrder(order): Observable<any> {
        const url = '/api/v1/menu/calculate.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        aq.auth(true);
        // let body = {
        //     storeId: store['id'],
        //     type: type
        // };
        // if (oi) body['orderitems'] = oi;
        const body = Order.toOrderData(order);
        aq.body = body;

        return aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }
    ordersCalculate(orders: Order[]) {
        const url = '/api/v1/menu/orders.json?type=byod&calculate=true';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        aq.auth(true);
        const body = {};
        let newOrders = orders.map(order => Order.toOrderData(order));
        body['orders'] = newOrders;
        aq.body = body;

        return aq.getJson().pipe(map((jo) => jo['data']));
    }
}
