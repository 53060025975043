import {Member} from 'aigens-ng-core';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {CookieService} from 'ngx-cookie-service';
import {Config} from '../../config';
import {UUID} from 'angular2-uuid';

@Injectable({providedIn: 'root'})
export class ConfigService {

    config: Config;
    settings: {};
    brandId: string;
    configRegion: string;
    app: any;
    firebase: any;
    entryPage: any;
    serverTime: number;
    sessionScript: any;
    openId: string; // wechat open id
    translations = {};
    sessionId: string;
    GAsuffix: string;
    // todo check the variable
    member: Member;
    isCourt = false;
    private debugTime: number;
    private host: string;
    private loaded = {};

    constructor(public storage: Storage, public cookieService: CookieService) {
        // super();
        this.config = new Config();

    }

    setRegion(name: string) {

        this.configRegion = name;
        this.setCookie('config.region', name);

    }

    getMember(): Member {

        return this.member;
    }

    restoreRegion() {
        this.configRegion = this.getCookie('config.region');
    }

    getRegion(): string {
        // this.configService.config.default.name;
        return this.configRegion;
    }

    get(name: string): string {
        const map = this.getConfig();

        return map[name];
    }

    getConfig() {
        let map = null;

        if (this.configRegion) {
            map = this.config[this.configRegion];
        }

        if (!map) {
            map = this.config.default;
        }

        return map;
    }

    getApi(): string {

        if (this.host) {
            return this.host;
        }

        return this.get('api');
    }

    getLocale(): string {

        let locale: string = this.getCookie('locale');
        if (!locale) {
            locale = this.get('locale');
        }

        return locale;

    }

    setLocale(locale: string) {

        this.setCookie('locale', locale);

    }

    getCountry(): string {

        return this.get('country');

    }

    getGroupId(): string {
        return this.get('groupId');
    }

    getBrandId(): string {
        return this.brandId;
    }

    setBrandId(brandId: string) {
        this.brandId = brandId;
    }

    putPreference(key: string, value: any) {
        this.storage.ready().then(() => {
            console.log('preference put', this.storage.driver + ' ' + key);
            this.storage.set(key, value);
        });

    }


    setUserToken() {

        if (this.getUserToken()) {
            console.log('user token already set');
            return;
        }

        let uuid = UUID.UUID().toString();

        // Cookie.set("_ut", uuid, 3650, '/', this.getRootDomain());

        this.cookieService.set('_ut', uuid, 3650, '/', this.getRootDomain());
    }

    getUserToken(): string {
        // return Cookie.get("_ut");
        return this.getCookie('_ut');
    }

    isDev() {

        // return "dev" === this.config.default['name'];
        return 'dev' === this.get('name');
    }

    isLocalhost() {

        return window.location.hostname === 'localhost';

    }

    isPrd() {

        /*
        if (this.config.default["production"]) {
            return true;
        }

        return false;
        */
        return this.get('production');
    }

    startSession(member: Member) {
        const now = new Date().getTime();

        let timeout = member.sessionTimeout;

        console.log('timeout in', timeout);

        if (member.admin && !timeout) {
            timeout = 60 * 24;
        }

        if (timeout && timeout > 0) {
            const expireMs = now + 60000 * timeout;
            this.setLocal('session.expire', expireMs);
            console.log('expire when', new Date(expireMs));
        }


    }

    isSessionExpired() {

        let result = false;

        const expire: number = this.getLocal('session.expire');
        if (expire) {
            const now = new Date().getTime();

            result = expire < now;
            console.log('diff', expire - now);

        }

        console.log('session expired?', result);

        return result;
    }

    refreshSession(member: Member) {

        if (!member) {
            return;
        }

        this.startSession(member);
    }

    setCookie(name: string, value: string) {
        // Cookie.set(name, value);
        // this.cookieService.put(name, value);
        // localStorage.setItem(name,value);
        this.cookieService.set(name, value, 0, '/');
    }

    deleteCookie(name: string) {
        // Cookie.set(name, value);
        // this.cookieService.put(name, value);
        // localStorage.setItem(name,value);
        this.cookieService.delete(name, '/');
    }

    getCookie(name: string): string {
        // return Cookie.get(name);
        // return this.cookieService.get(name);


        return this.cookieService.get(name);
    }


    getPreference(key: string): Promise<any> {
        return this.storage.ready().then(() => {
            console.log('preference get', this.storage.driver + ' ' + key);
            return this.storage.get(key);
        });
    }

    setLocal(key: string, object: any) {

        localStorage.setItem(key, JSON.stringify(object));

    }

    getLocal(key: string): any {
        const str = localStorage.getItem(key);
        if (!str || str === 'undefined') {
            return null;
        }
        return JSON.parse(str);
    }

    loadScript(url: string, callback: Function, param?: any) {

        const scriptId = param ? param['id'] : null;

        if (!scriptId && this.loaded[url] && url.indexOf('session.js') === -1) {
            console.log('script already loaded!!!', url);
            callback();
            return;
        } else if (scriptId) {
            const element = document.getElementById(scriptId);

            if (element) {
                element.remove();
            }
        }

        // Adding the script tag to the head as suggested before

        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        if (param) {
            for (const key in param) {
                script.setAttribute(key, param[key]);
            }
        }

        script.onload = () => {

            this.loaded[url] = true;
            if (callback) {
                // script.onreadystatechange = callback;
                callback();
            }
        };

        // Fire the loading
        if (url.indexOf('session.js') !== -1 && this.sessionScript) {
            head.removeChild(this.sessionScript);
            console.log('reload session.js');
        }

        const temp = head.appendChild(script);
        if (url.indexOf('session.js') !== -1) {
            this.sessionScript = temp;
        }
    }

    loadScriptJS(path: string, callback: Function, param?: any, forceLoad?: boolean) {


        if (this.loaded[path] && !forceLoad) {
            console.log('script already loaded!!!', path);
            callback();
            return;
        }

        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = path;

        if (param) {
            for (const key in param) {
                script.setAttribute(key, param[key]);
            }
        }

        script.onload = () => {

            this.loaded[path] = true;
            if (callback) {
                // script.onreadystatechange = callback;
                callback();
            }
        };

        head.appendChild(script);

    }

    getVersionCode(): string {
        return this.config['version'];
    }

    getCustomizationId(): string {
        return this.config['customizationId'];
    }

    // returns the current time or debug time
    getTime() {

        if (this.config.default.name === 'pos') {
            return new Date().getTime();
        }

        if (this.debugTime) {
            return this.debugTime;
        }
        return new Date().getTime();
    }

    setDebugTime(time: number) {
        this.debugTime = time;
    }

    setHost(host: string) {
        this.host = host;
    }

    isChina() {
        return this.get('country') === 'cn';
    }

    getSessionId(): string {

        if (this.sessionId) {
            return this.sessionId;
        }
        return this.getCookie('sid');
    }

    setSessionId(sessionId: string) {
        this.sessionId = sessionId;
        this.setCookie('sid', this.sessionId);
    }

    clearSessionId() {
        this.sessionId = null;
        this.deleteCookie('sid');
    }

    private getRootDomain(): string {
        let temp = location.hostname.split('.').reverse();

        let rootDomain = temp[0];

        if (temp[1]) {
            rootDomain = temp[1] + '.' + rootDomain;
        }

        console.log('root Domain', rootDomain);
        return rootDomain;
    }

}
