import { Brand, MathUtils, Order, OrderManager, Period, Store } from 'aigens-ng-core';
import { Injectable } from '@angular/core';
import { OrderService } from '../services/order.service';


@Injectable()
export class MultOrderManager {
    orderManagerMap: {};
    currentPeriods: {};
    enableMutlOrder = false;
    brand: Brand;
    payments: any;

    serverOrders: Order[];// 保存server calculate 回来得order数据,优先级最高

    constructor(public orderService: OrderService) {
        this.orderManagerMap = {};
        this.currentPeriods = {};
    }

    setOrderManagerMap(orderManager: OrderManager, storeId: number) {
        this.orderManagerMap[storeId] = orderManager;
    }

    getOrderManager(storeId: number): OrderManager {
        return this.orderManagerMap[storeId];
    }

    getOrderManagers(): OrderManager[] {
        let orderManager: OrderManager[] = [];
        for (let key in this.orderManagerMap) {
            orderManager.push(this.orderManagerMap[key]);
        }
        return orderManager;
    }

    getOrders(): Order[] {
        let orders: Order[] = [];
        for (let key in this.orderManagerMap) {
            if (!this.orderManagerMap[key].isEmpty()) {
                orders.push(this.orderManagerMap[key].order);

            }
        }
        return orders;
    }

    getStores(): Store[] {
        let stores: Store[] = [];
        for (let key in this.orderManagerMap) {
            if (!this.orderManagerMap[key].isEmpty()) {
                stores.push(this.orderManagerMap[key].store);
            }
        }
        return stores;
    }

    getStore(storeId: number): Store {
        return this.orderManagerMap[storeId].store;
    }

    clearAll() {
        this.orderManagerMap = {};
        this.currentPeriods = {};
    }

    deleteOrderManager(storeId: number) {
        delete this.orderManagerMap[storeId];
    }

    getOrderCount(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum += orderManager.count;
        });
        return sum;
    }

    getOrderTotal(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum = Number((sum + orderManager.order.total).toFixed(2));
        });
        return sum;
    }

    getOrderGrandTotal(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum = Number((sum + orderManager.order.grandTotal).toFixed(2));
        });

        try {
            if (this.orderService && this.orderService.courtOrderDiscount && this.orderService.courtOrderDiscount.discount) {
                sum -= this.orderService.courtOrderDiscount.discount;
                if (sum <= 0) {
                    sum = 0;
                }
            }
            return MathUtils.round(sum, 2);
        } catch (error) {
            return sum;
        }
    }

    setStoreCurrentOrderingPeriod(storeId: number, period: Period) {
        this.currentPeriods[storeId] = period;
    }

    deleteStoreCurrentOrderingPeriod(storeId: number) {
        delete this.currentPeriods[storeId];
    }

    getStoresCurrentOrderingPeriod(): [Period[], number[]] {
        let periods: Period[] = [];
        let storeIds: number[] = [];
        for (let key in this.currentPeriods) {
            periods.push(this.currentPeriods[key]);
            storeIds.push(Number(key));
        }
        return [periods, storeIds];
    }



}
