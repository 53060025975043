import { NgModule } from '@angular/core';
import { PopupDialog } from './popup/popup';
import { FreeGiftDialog } from './free-gift/free-gift';
import { MemberPopupDialog } from './member-popup/member-popup';
import { ModifierDialog } from './modifier/modifier';
import { PasscodeDialog } from './passcode/passcode';
import { PreOrderDialog } from './pre-order/pre-order';
import { RewardListDialog } from './reward-list/reward-list';
import { SelectTablePage } from './select-table/select-table';
import { SurveyDialog } from './survey/survey';
import { TransactionsConfirm } from './transactions-confirm/transactions-confirm.component';
import { VariationDialog } from './variation/variation';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UnlockerModule } from '../shared/components/unlocker/unlocker.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { SecureVerifyModule } from './secure-verify/secure-verify.module';
import { ExternalWebModule } from './external-web/external-web.module';
import { TermsConditionsModule } from './terms-conditions/terms-conditions.module';
import { ConfigService } from '../core/services/config.service';
import { createTranslateLoader } from '../shared/utilities/createTranslateLoader';
import { ThemeService } from '../core/services/theme.service';
import { MultiStepComboModule } from '../routes/item/multi-step-combo/multi-step-combo.module';
import { ComboPageModule } from '../routes/item/combo-page/combo-page.module';
import { OfferListDialog } from './offer-list/offer-list';
import { VariationScrollDialog } from './variation-scroll/variation-scroll';
import { ModifierScrollDialog } from './modifier-scroll/modifier-scroll';
import { SidebarModifierDialog } from './sidebar-modifier/sidebar-modifier';
import { UnlockPopupDialog } from './unlockPopup/unlockPopup';
import { PickupTimeDialog } from './pickupTime/pickupTime';
import { IsLoginMemberDialogs } from './is-login-member/is-login-member';
import { SharedModule } from '../shared/shared.module';
import { AddressDialog } from './address-dialog/address-dialog.component';
import { DeliveryLoginPage } from '../routes/member/delivery-login/delivery-login.page';
import { DeliveryLoginRevisePage } from '../routes/member/delivery-login-revise/delivery-login-revise.page';
import { SelectStoreFilterComponent } from './select-store-filter/select-store-filter.component';
import { LoginModal } from './login-modal/login-modal.page';
import { ResetPasswordPage } from '../routes/member/reset-password/reset-password-page';
import { SearchAddressPage } from '../routes/member/search-address/search-address.page';
import { JoinOrLogoutModal } from './join-or-logout-modal/join-or-logout-modal.page';
import { OrderHelpModal } from './order-help-modal/order-help-modal.page';
import { StampRedeemPage } from '../routes/member/stamp-redeem/stamp-redeem';

@NgModule({
    declarations: [
        PopupDialog,
        FreeGiftDialog,
        MemberPopupDialog,
        ModifierDialog,
        ModifierScrollDialog,
        SidebarModifierDialog,
        PasscodeDialog,
        PreOrderDialog,
        RewardListDialog,
        SelectTablePage,
        SelectStoreFilterComponent,
        SurveyDialog,
        TransactionsConfirm,
        VariationDialog,
        VariationScrollDialog,
        UnlockPopupDialog,
        PickupTimeDialog,
        IsLoginMemberDialogs,
        OfferListDialog,
        AddressDialog,
        DeliveryLoginPage,
        LoginModal,
        JoinOrLogoutModal,
        OrderHelpModal,
        DeliveryLoginRevisePage,
        ResetPasswordPage,
        SearchAddressPage,
        StampRedeemPage
    ],
    entryComponents: [VariationDialog, PopupDialog,
        UnlockPopupDialog,
        PickupTimeDialog,
        IsLoginMemberDialogs,
        FreeGiftDialog,
        MemberPopupDialog,
        AddressDialog,
        DeliveryLoginPage,
        LoginModal,
        JoinOrLogoutModal,
        OrderHelpModal,
        SidebarModifierDialog,
        ModifierDialog,
        PreOrderDialog,
        RewardListDialog,
        SelectTablePage,
        SelectStoreFilterComponent,
        SurveyDialog,
        TransactionsConfirm,
        OfferListDialog,
        ResetPasswordPage,
        SearchAddressPage,
        VariationScrollDialog,
        StampRedeemPage],
    imports: [
        ReactiveFormsModule,
        UnlockerModule,
        IonicStorageModule,
        SecureVerifyModule,
        ExternalWebModule,
        TermsConditionsModule,
        MultiStepComboModule,
        ComboPageModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
        IonicModule,
        SharedModule
    ],
    exports: [
        PopupDialog,
        FreeGiftDialog,
        MemberPopupDialog,
        AddressDialog,
        DeliveryLoginPage,
        LoginModal,
        JoinOrLogoutModal,
        SidebarModifierDialog,
        OrderHelpModal,
        DeliveryLoginRevisePage,
        ModifierScrollDialog,
        PasscodeDialog,
        RewardListDialog,
        SelectTablePage,
        SelectStoreFilterComponent,
        SurveyDialog,
        TransactionsConfirm,
        VariationDialog,
        OfferListDialog,
        VariationScrollDialog,
        UnlockPopupDialog,
        IsLoginMemberDialogs,
        OfferListDialog,
        ResetPasswordPage,
        SearchAddressPage,
        StampRedeemPage
    ]
})
export class DialogsModule {
}
