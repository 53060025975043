import { Data, Store } from 'aigens-ng-core';
import 'rxjs/Rx';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreService extends BaseService {

    aq: AQuery;
    storeCountryCodesArr: any;

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();
        this.aq = new AQuery(http, configs);
    }

    // use in store list
    searchStores(groupId: any, q, latitude: number = null, longitude: number = null, regionCodes: string[] = null, radius: number = null, limit: number = null) {

        const url = '/api/v1/store/store.json?groupId=' + groupId;

        const params = {};
        params['q'] = q;

        if (latitude != null && longitude != null) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }

        if (radius != null) {
            params['radius'] = radius;
        }

        if (limit != null) {
            params['limit'] = limit;
        }

        if (regionCodes != null && regionCodes.length > 0) {
            params['region'] = regionCodes.join(',');
        }

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Store, jo['data'])));

    }


    verifyTableCode(code: string, storeId: number): Observable<any> {
        const url = '/api/v1/menu/spot.json?storeId=' + storeId + '&code=' + code;

        this.aq.url = url;
        this.aq.method = 'get';


        return this.aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }

    // search store by keys
    searchStore(key, id) {
        let url = '/api/v1/store/store.json';


        const params = {};
        params['q'] = 'feature:byod';
        params[key] = id;

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Store, jo['data'])));
    }

    // search delivery store
    searchDeliveryStore(brandId, country, paramObj) {
        let url = '/api/v1/shipping/availability.json';
        let { postal, latitude, longitude,addressId } = paramObj;

        const params = {};
        params['brandId'] = brandId;
        params['country'] = country;
        if (postal) {
            params['postal'] = postal;
        }

        if (latitude && longitude) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }

        // if (addressId) {
        //     params['addressId'] = addressId;
        // }

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => {
            console.log('searchDeliveryStore jo', jo);
            return jo['data'];
        }));
    }

    getCouriers(storeId, country, addressId) {
        let url = '/api/v1/shipping/availability.json';

        const params = {};
        params['storeId'] = storeId;
        params['country'] = country;
        if (addressId) {
            params['addressId'] = addressId;
        }

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => {
            // console.log('getCouriers jo', jo);
            return jo['data'];
        }));
    }

    searchStoreByPostal(keys: string[] = [], groupId: any, postal: number, country: any) {
        let url = '/api/v1/store/store.json';
        // let keywords = 'feature:';
        // keys.forEach((key)=>{
        //     keywords = keywords + key;
        // });
        console.log('searchStoreByKey', url);

        const params = {};
        params['q'] = 'feature:byod';
        params['groupId'] = groupId;
        params['postal'] = postal;
        params['country'] = country;

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Store, jo['data'])));
    }

    getStoreRegionsByBandId(brandId) {
        let url = '/api/v1/store/directory.json?brandId=' + brandId;

        console.log('getStoreRegionsByBandId', url);

        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => {
            console.log('getStoreRegionsByBandId jo', jo);
            return jo['data'];
        }));
    }

    setStoreCountryCodesArr(arr) {
        this.storeCountryCodesArr = null;
        if (arr && arr.length > 0)
            this.storeCountryCodesArr = arr;
    }

    // 计算距离 s: user currentPos
    calDistance(lat2, lon2, pos, unit = 'K') {
        if (!pos || !pos.latitude || !pos.longitude) return;
        let lat1 = pos.latitude;
        let lon1 = pos.longitude;
        // console.log('维度', lat1, lon1, lat2, lon2);
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            let radlat1 = Math.PI * lat1 / 180;
            let radlat2 = Math.PI * lat2 / 180;
            let theta = lon1 - lon2;
            let radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') { dist = dist * 1.609344; }
            if (unit === 'N') { dist = dist * 0.8684; }
            return dist;
        }
    }
}
