import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BaseService {

    getOrderSesisonUrl(storeId, mode, fields = 'membership'){
        let url = '/api/v1/menu/session.json?';
        url = url + 'storeId=' + storeId + '&mode=' + mode + '&fields=' + fields;
        return url;
    }
    getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

}
