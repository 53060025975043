import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

/**
 * Generated class for the UnlockerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */

//TODO: implement the component

@Component({
  selector: 'unlocker',
  templateUrl: 'unlocker.html'
})
export class UnlockerComponent {
  setIntId;
  @Output() unlocked: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('unlock') input: any;
  private _knobOnly = false;
  Range;
  // @ViewChildren(RangeKnob) private _knobs: QueryList<RangeKnob>;

  @Input()
  get knobOnly(): boolean {
    return this._knobOnly;
  }
  set knobOnly(val: boolean) {
    this._knobOnly = this.isTrueProperty(val);
  }

  // private _events: UIEventManager = new UIEventManager();

  constructor() {
  }
  // ngAfterViewInit() {
  //   console.log('122211');

  //   this._events.pointerEvents({
  //     pointerDown: this.pointerDown.bind(this),
  //     pointerMove: this.pointerMove.bind(this),
  //     pointerUp: this.pointerUp.bind(this)
  //   });
  // }



  pointerMove() { }
  pointerUp() { }

  pointerDown(ev: UIEvent): boolean {

    if (this._knobOnly) {
      console.log('11111');

      // //current knob value
      // let knobValue = this._knobs.first.value;

      // //ratio and value of pointer coordination
      // let pointerRatio = (this._start.x - rect.left) / (rect.width);
      // let pointerValue = this.ratioToValue(pointerRatio);

      // //check if pointer is close to the knob
      // var dif_factor = (this._max - this._min) * 0.06;
      // var dif = Math.abs(pointerValue - knobValue);
      // if (dif > dif_factor) {
      //   return false;
      // }
    }
    return false;
  }

  checkUnlock(event: Event) {
    const theRange = Number(this.input.nativeElement.value);
    if (theRange == 100) {
      this.unlockAction();
    }
    this.Rebound(theRange);

  }

  Rebound(theRange: number) {
    this.setIntId = setInterval(() => {
      if (this.input.nativeElement.value > 0) {
        this.input.nativeElement.value = theRange--;
      } else {
        this.input.nativeElement.value = 0;
        clearInterval(this.setIntId);
      }
    }, 1);
  }

  unlockAction() {
    this.unlocked.emit(true);
  }

  isTrueProperty = (val: any): boolean => {
    if (typeof val === 'string') {
      val = val.toLowerCase().trim();
      return (val === 'true' || val === 'on' || val === '');
    }
    return !!val;
  }
}
