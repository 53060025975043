import {Component, EventEmitter, Injector, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {isEmailValid, isPhoneValid} from '../../utilities/checkFormatTool';
import {MobileBasePage} from 'src/app/core/base/mobile-base-page';

@Component({
    selector: 'app-accept-marketing',
    templateUrl: './accept-marketing.component.html',
    styleUrls: ['./accept-marketing.component.scss'],
})
export class AcceptMarketingComponent extends MobileBasePage implements OnInit {

    marketing1 = false;
    marketing2 = false;

    constructor(private injector: Injector, private cd: ChangeDetectorRef) {
        super(injector);
        this.marketing1 = this.orderService.marketing1;
        this.marketing2 = this.orderService.marketing2;

        console.log(this.systemConfigService);
    }

    ngOnInit() {

        // console.log('phone required?',this.needPhone, this.settings.phoneRequired);

    }

    checkMarketing(ev, num) {
        console.log('checkMarketing', this.marketing1, this.marketing2);
        switch (num) {
            case 1: {
                this.orderService.marketing1 = this.marketing1;
                this.cd.detectChanges();

                return;
            }
            case 2: {
                this.orderService.marketing2 = this.marketing2;
                this.cd.detectChanges();
                return;
            }
        }
    }
}
