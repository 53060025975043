import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';

@Component({
    selector: 'order-help-modal',
    templateUrl: './order-help-modal.page.html',
    styleUrls: ['./order-help-modal.page.scss'],
})
export class OrderHelpModal extends MobileBasePage implements OnInit, OnDestroy {



    constructor(injector: Injector) {
        super(injector);

    }

    dismissClicked(params?) {
        console.log('OrderHelpModal closing');
        this.modalController.dismiss(params);
    }


}
