import { BasicAuth } from '../../shared/utilities/basic-auth';
import { ConfigService } from '../services/config.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderService } from '../services/order.service';

export class AQuery {

    host: string;
    http: HttpClient;
    method: string;
    params: {};
    url: string;
    private headers: {};
    configs: ConfigService;
    body: {};
    shouldAuth = false;
    authorization: BasicAuth;
    data: ArrayBuffer;

    constructor(http: HttpClient, configs: ConfigService) {
        this.http = http;
        this.configs = configs;
        this.clear();
    }

    auth(auth: boolean): AQuery {
        this.shouldAuth = auth;
        return this;
    }

    setHeaders(headers = {}) {
        this.headers = headers;
    }
    basicAuth(auth: BasicAuth): AQuery {
        this.authorization = auth;
        return this;
    }

    public getJson(): Observable<Object> {

        const result = this.doHttp(); // TODO:pipe(map(r => this.processJson(r)));
        this.clear();
        return result;
    }

    processJson(response) {

        console.log('response', response);
        const dateStr = response.headers.get('Date');
        let json = response.body;

        if (dateStr && json) {
            const date = new Date(dateStr);

            json['time'] = date.getTime();
        }


        return json;
    }

    protected getParams(inputs: {}): HttpParams {
        let params: HttpParams = new HttpParams({ fromObject: inputs });

        const groupId = this.configs.getGroupId();

        if (groupId && !inputs['groupId'] && this.url.indexOf('groupId') === -1) {
            params = params.append('groupId', groupId);
        }

        let lang = this.configs.getLocale();
        console.log('TCL: AQuery -> lang', lang);
        if (lang && !inputs['locale'] && this.url.indexOf('locale') === -1) {
            params = params.append('locale', lang);
        }

        const country = this.configs.getCountry();
        if (country && !inputs['country'] && this.url.indexOf('country') === -1) {
            params = params.append('country', country);
        }
        console.log('params input', inputs);
        return params;
    }

    private clear() {
        this.method = 'get';
        this.params = {};
        this.url = null;
        this.body = null;
        this.headers = {};
        this.shouldAuth = false;
        this.authorization = null;
        this.data = null;
    }

    private getDefaultHeaders(inputs: {}): HttpHeaders {
        let headers = new HttpHeaders(inputs);

        console.log('should auth?', this.shouldAuth || this.authorization);
        if (this.authorization) {
            headers = headers.append('AUTHORIZATION', 'Basic ' + this.authorization.getAuth());
        } else {
            if (this.shouldAuth && this.configs.getSessionId() && this.configs.getSessionId() !== 'null') {
                headers = headers.append('sid', this.configs.getSessionId());
            }
        }

        console.log('headers input', inputs);
        console.log('headers generated', headers);
        return headers;
    }

    private getHost() {
        if (this.host) {
            return this.host;
        }
        return this.configs.getApi();
    }

    private doHttp(): Observable<Object> {

        let url = this.url;

        if (url.indexOf('/') === 0) {
            url = this.getHost() + url;
        }

        const params = this.params;
        const headers = this.headers;
        const method = this.method;

        const searchParams = this.getParams(params);
        const httpHeaders = this.getDefaultHeaders(headers);


        console.log(method, url, searchParams, httpHeaders);

        if ('get' === method) {
            return this.http.get<Object>(url, { params: searchParams, headers: httpHeaders, observe: 'response' }).map(r => this.processJson(r));
        } else if ('post' === method || 'put' === method || 'delete' === method) {

            console.log('ABC');

            let body = this.body;
            if (this.body) {
                console.log('json body');
                const newHttpHeaders = httpHeaders.append('Content-Type', 'application/json');
                body = JSON.stringify(this.body);

                if ('post' === method) {
                    return this.http.post<Object>(url, body, { params: searchParams, headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                } else if ('put' === method) {
                    return this.http.put<Object>(url, body, { params: searchParams, headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                } else if ('delete' === method) {
                    return this.http.delete<Object>(url, { params: searchParams, headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                }
            } else if (this.data) {
                console.log('raw bytes');
                if ('post' === method) {
                    return this.http.post<Object>(url, this.data);
                }
            } else {
                console.log('urlencoded');
                const newHttpHeaders = httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
                let str = searchParams.toString();


                str = str.replace(/\+/g, '%2b');

                console.log('urlencoded body', str);

                if ('post' === method) {
                    return this.http.post<Object>(url, str, { headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                } else if ('put' === method) {
                    return this.http.put<Object>(url, str, { headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                } else if ('delete' === method) {
                    return this.http.delete<Object>(url, { headers: newHttpHeaders, observe: 'response' }).map(r => this.processJson(r));
                }


            }


        }/* else if ("put" == method) {

            var body = this.body;
            if (this.body) {
                httpHeaders.set("Content-Type", "application/json"),
                    body = JSON.stringify(this.body);

                return this.http.put(url, body, { search: searchParams, headers: httpHeaders }).map(r => r.json());
            } else {
                httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
                body = searchParams.toString();
                return this.http.put(url, body, { headers: httpHeaders }).map(r => r.json());
            }

        }*/


    }


}
