import { Component, Injector } from "@angular/core";
import { MobileBasePage } from '../../../core/base/mobile-base-page';
import { Data, Offer } from "aigens-ng-core";


@Component({
    selector:'page-stamp-redeem',
    templateUrl:'stamp-redeem.html'
})

export class StampRedeemPage extends MobileBasePage  {
    reward:any;
    stampCard:any;
    index:number = 0;
    stampStatus:string='';
    imgSrc:string;
    constructor(injector:Injector) {
        super(injector)
        this.reward = this.navParams.get('reward');
        this.stampCard = this.navParams.get("stampCard");
        this.index = this.navParams.get('index');
        this.stampStatus = this.navParams.get('stampStatus');
    }

    dismiss() {
        this.modalController.dismiss();
    }

    redeemClick(){
        let status = this.stampStatus;
        if(status !== 'canRedeem') {
            this.modalController.dismiss()
        }
        let stampCardTemplateId,stampIdx;
        stampCardTemplateId = this.stampCard.template.id;
        stampIdx = this.index+1
        this.memberService.manualRedeemStampCardReward(stampCardTemplateId,stampIdx).subscribe((data) => {
            console.log(data);
            if(data) {
                this.showAlert('Redeemd Success','')
                this.modalController.dismiss('redeemed')
            }
        }, err => {
            this.showAlert("Redeem Fail",'')
            this.modalController.dismiss('redeemed')
        })
    }

    getImageSrc():string {
        let src="";
        this.reward = Data.toData(Offer,this.reward);
        console.log(this.reward)
        src = 'assets/imgs/logo_hd.png';
        if(this.reward.getDefaultImage()) {
            src = this.reward.getDefaultImage();
        }
        return src;
    }
}