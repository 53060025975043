import {NgModule} from '@angular/core';
import {ScrollHideDirective} from './scroll-hide/scroll-hide';
import {ParallaxHeaderDirective} from './parallax-header/parallax-header';
import {ThrottleClickDirective} from './throttle-click/throttle-click';
import {DefaultImgDirective} from './default-img/default-img.directive';
import {ThrottleClickModule} from './throttle-click/throttle-click.module';

@NgModule({
    declarations: [
        ScrollHideDirective,
        ParallaxHeaderDirective,
        DefaultImgDirective
    ],
    imports: [],
    exports: [
        ScrollHideDirective,
        ParallaxHeaderDirective,
        DefaultImgDirective
    ]
})
export class DirectivesModule {
}
