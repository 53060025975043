import { Ad, Brand, Store } from 'aigens-ng-core';
import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { RouteTable } from 'src/app/core/route-table';
import { NavigationService } from '../../core/services/navigation.service';
import { MemberService } from '../../core/services/member.service';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-popup',
    templateUrl: 'popup.html',
    styleUrls: ['popup.scss']
})
export class PopupDialog {
    store: Store;
    brand: Brand;
    courtId: any;
    ads: Ad[];
    slideOpts = {
        initialSlide: 0,
        speed: 400,
        autoplay: {
            delay: 4000
        },
        loop: true,
    };
    adsArr: any = [];

    constructor(public memberService: MemberService, public navigationService: NavigationService, public modalController: ModalController, public navParams: NavParams) {

        this.store = this.navigationService.getNavParams('store');
        this.brand = this.navigationService.getNavParams('brand');
        this.courtId = this.navigationService.getNavParams('courtId');
        this.ads = this.navigationService.getNavParams('ads');
        // this.setAds(this.ads);
        console.log('nav', navParams, this.store);
        console.log('popup', this.ads);
    }


    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

    adClicked(ad = null) {
        console.log('ad clicked', ad);
        this.modalController.dismiss(ad['links']);
        // this.pushByUrl()
        if (ad['links']) return;
        if (this.memberService.getMember()) {
            this.navigationService.pushByUrl(RouteTable['MemberRewardPage']);
        } else {
            if (this.store) {
                this.navigationService.pushByName('LoginPage', {
                    store: this.store,
                    fromPage: 'ByodSplashPage', logincb: () => {
                        // this.next();
                    }
                });
            } else if (this.brand) {
                this.navigationService.pushByName('LoginCourtPage', {
                    courtId: this.courtId,
                    features: this.brand.features,
                    brand: this.brand,
                    country: this.getCourtCountry()
                });
            }

        }
    }

    getCourtCountry() {
        let stores = this.brand && this.brand.stores;
        let country;
        if (stores && stores.length > 0) {
            country = stores[0].country;
        }
        return country;
    }


}
