import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-enlargePage',
  templateUrl: './enlargePage.html',
  styleUrls: ['./enlargePage.scss']
})
export class EnlargePage implements OnInit {
  @Input() imgParams: string;
  imgurl: string;
  modalController: ModalController;
  constructor(navParams: NavParams, modalController: ModalController) {
    console.log('TCL: EnlargePage', EnlargePage);

    this.modalController = modalController;
    this.imgurl = navParams.get('imgUrl');
  }
   modalDismiss() {
    this.modalController.dismiss();
  }
  ngOnInit() {
  }

}
