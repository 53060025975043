import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import Cleave from 'cleave.js';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {

  @Input()
  hasCreditCard = false;

  @Output()
  creditcardChange = new EventEmitter<any>();

  cleaveCreditCard: Cleave;
  cleaveExpDate: Cleave;

  creditcardType: string;

  name: string;
  number: string;
  expDate: string;
  cvv: number;

  isNameDirty = false;

  creditcard: any = {};

  constructor() { }

  ngOnInit() {
    this.cleaveCreditCard = new Cleave('.number-input', {
      creditCard: true,
      onCreditCardTypeChanged: (type) => {
        this.creditcardType = type;
      }
    });

    this.cleaveExpDate = new Cleave('.exp-date-input', {
      date: true,
      datePattern: ['m', 'y']
    });
  }

  handleNameInput() {
    this.creditcard.name = this.name;
    this.isNameDirty = true;
    this.creditcardChange.emit(this.creditcard);
  }

  handleNumberInput() {
    this.creditcard.number = this.cleaveCreditCard.getRawValue();
    this.creditcardChange.emit(this.creditcard);
  }

  handleExpDateInput() {
    let date = this.cleaveExpDate.getRawValue();
    if (date.length === 4) {
      this.creditcard.month = date.substring(0, 2);
      this.creditcard.year = date.substring(2);
      this.creditcardChange.emit(this.creditcard);
    } else if (date.length === 0) {
      this.creditcard.month = '';
      this.creditcard.year = '';
      this.creditcardChange.emit(this.creditcard);
    }
  }

  handleCvvInput() {
    this.creditcard.cvv = this.cvv;
    this.creditcardChange.emit(this.creditcard);
  }

}
